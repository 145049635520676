/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosError, AxiosResponse } from "axios";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { Questioncardwidget } from "../../../_metronic/partials/widgets";
import {
  answeredvalues,
  fetchdata,
  fetchdatapreview,
  fetchoverview,
} from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Online, Offline } from "react-detect-offline";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import "./Test.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import Googletranslate from "../../../_metronic/layout/components/header/header-menus/Googletranslate";
import { Player } from "video-react";
import { handlestartrecording, handlestoprecording } from "../../../Redux/ScreenRecord/ScreenRecord";
import FullscreenModal from "./modals/FullscreenModal";
import WindowSwitchModal from "./modals/WindowSwitchModal";
import Peer from "peerjs";
import { io, Socket } from "socket.io-client";
import useConsoleLog from "../../../hooks/useConsoleLog";
import KickedUserModal from "./modals/KickedUserModal";
import CryptoJS from 'crypto-js';
import ScreenWarn from "./modals/ScreenWarn";
import CameraWarn from "./modals/CameraWarn";
import { OGPAdd } from "./Prerequisites";
import { useDevToolsStatus } from "./useDevToolsStatus";
import InspectDevToolsModal from "./modals/InspectDevToolsModal";
import ShareEntireScreenModal from "./modals/ShareEntireScreenModal";
import { cleanObject } from "./clearnAnswerObjects";
import Slide from '@mui/material/Slide';
import useNetworkStatus from "../../../utils/CustomHooks/useNetworkStatus";
import InternetTurnOffModal from "./modals/InternetTurnOffModal";
import formatFileSize from "../../../utils/fileFormatSize";
import { sha1 } from 'js-sha1';
import DataUploadingModal from "./modals/DataUploadingModal";
import { returnUTCDate } from "../../../utils/formatDates";
import getOptimizedStreamConstraints from "../../../utils/getOptimizedStreamConstraints";
import { retryRequest } from "../../../utils/retryRequests";

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = {};

interface LogsPayload {
  data: any;
  cryptvalue: string | undefined;
  type?: string;
}

export enum EventType {
  Inspect = "inspect",
  Faces = "faces",
  Screen = "screen",
  Camera = "camera",
  FullScreen = "full_screen",
  TabSwitch = "tab_switch",
}

enum AntiCheatingColumnName {
  cameraStopped = 'camera_stopped',
  screenStopped = 'screen_stopped',
  inspectElementEvents = 'inspect_element_events',
  facesDetected = 'faces_detected',
  fullscreenEvents = 'fullscreen_events',
  tabSwitchEvents = 'tab_switch_events',
}

enum LogType {
  SLOW_INTERNET = "slow_internet",
  TYPING_AND_MOUSE = "typing_and_mouse_events",
  SCREEN_RECORDING_CHUNK = "screen_recording_chunks",
  SCREEN_RECORDING_DIRECT_CLOUD = "screen_recording_direct_cloud",
}

export type AntiCheatingEventsType = {
  eventType?: EventType;
  test_assessment_id: string;
  multiple_faces?: boolean;
  no_faces?: boolean;
  end?: boolean;
  start?: boolean;
  time: string;
  columnName?: string;
}

type USER_ACTIVITY = {
  clicks: number,
  keystrokes: number,
  scrolls: number,
  totalMinutes: number,
  perMinuteData: any[],
}

const options = {
  icon: toAbsoluteUrl("/media/logos/mTestHubIconLogo.png"),
};

export const encryptData = (data: any) => {
  // @ts-ignore
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRYPTVALUE).toString();
};
export const decryptData = (encryptedData: any) => {
  // @ts-ignore
  const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTVALUE);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

//caluclate checksum of the screen recording chunk data to be sent to the server
const calculateChecksum = (data: any) => {
  return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
};

// const cache = new Map();


//function to send anticheating events to the server
export const antiCheatingEvents = async (data: AntiCheatingEventsType, id:any, sessionId:any) => {
  // const cacheKey = JSON.stringify(data);

  // // Define a helper function to remove cached items based on conditions
  // const removeFromCache = (eventType: any, flag: any) => {
  //   for (let [key, value] of Array.from(cache.entries())) {
  //     const cachedData = JSON.parse(key);
  //     if (cachedData.eventType === eventType && cachedData[flag]) {
  //       cache.delete(key);
  //     }
  //   }
  // };

  // // Check conditions for `start` or `end` in `data`
  // if (data.end) {
  //   removeFromCache(data.eventType, 'start');
  // } else if (data.start) {
  //   removeFromCache(data.eventType, 'end');
  // }

  // if (cache.has(cacheKey)) {
  //   return cache.get(cacheKey);
  // }
  if (!sessionId || Number(sessionId) === 0 || sessionId === null) {
    return;
  }

  try {
    const result = await retryRequest(`getexamdata/anticheatingevents/${id}?sessionId=${sessionId}`, data);
    // cache.set(cacheKey, result);
    return result;
  } catch (error) {
    console.error('Error occurred while sending anti-cheating events:', data);
  }

  // try {
  //   // Perform the API call
  //   await axios.post(`getexamdata/anticheatingevents`, data);
  //   const result = { submission: 'completed' };
  //   cache.set(cacheKey, result);
  // } catch (error) {
  //   console.error('Error occurred while sending anti-cheating events:', error);
  // }
};

//function to log slow internet flag to the server
const addSlowInternetFlag = (sendLog: any, user_email: string, test_id: string, remainingChunks: number) => {
  console.log("Slow internet detected: Complete recording not uploaded");
  sendLog(`Slow internet detected during upload process for user ${user_email} for test ${test_id} remainingChunks: ${remainingChunks}`, false);
};

const Testpage = (props: Props) => {
  const { id } = useParams();
  const { tokenid } = useParams();

  const localstoragedata = JSON.parse(
    localStorage.getItem("userdetails") as string
  );

  // const totalTestDuration = useAppSelector((state) => state?.tests?.totaltime);

  const sessionId = useRef<number>(0);
  const [particularvalue, setparticularvalue] = React.useState<any>({});
  const [savetimer, setsavetimer] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [autorecoverydata, setautorecoverydata] = useState<any>({});
  const [dataforrecover, setdataforrecover] = useState<any>([]);
  // const [geolocationdata, setgeolocationdata] = useState<any>({});
  const geolocationdata = React.useRef<any>({
    geolocationlongitude: 0,
    geolocationlatitude: 0,
    accuracy: 0,
  })

  const [peerCallInstance, setPeerCallInstance] = useState<any>(null);
  const [socketinstance, setsocketinstance] = useState<any>(null);
  const [socketinstance2, setsocketinstance2] = useState<any>(null);
  const [peerInstance, setPeerInstance] = useState<any>(null);
  const [userId, setUserid] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [manualSubmit, setManualSubmit] = useState(false);
  const [chunkSize, setChunkSize] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenKick, setIsModalOpenKick] = useState(false);

  const [Recorder, setRecorder] = React.useState<any>("");

  const [screenShareLoading, setScreenShareLoading] = React.useState<boolean>(false);

  const [facesDetected, setFacesDetected] = React.useState<any>(0);

  const [openmodalForInspectDevTools, setIsModalOpenInspectDevTools] = useState(false);

  const [isModalOpenresize, setIsModalOpenresize] = useState(false);

  const [displayEntireScreenModal, setDisplayEntireScreenModal] = React.useState<boolean>(false);

  const isDevToolsOpen = useDevToolsStatus();

  const location = useLocation();

  const sequenceNumber = useRef<number>(1);

  const [userStoppedScreenSharing, setUserStoppedScreenSharing] = useState(false);

  const [alertSnackBarOpen, setAlertSnackBarOpen] = React.useState(false);

  const timerComplete = useAppSelector((state) => state?.tests?.timerComplete);

  const [peerId, setPeerId] = useState("");

  let countRef = useRef(0);


  const facesEndRef = useRef(0);

  const modalsRef = useRef<any>({
    camera_modal: 0,
    full_screen_modal: 0,
    inspect_modal: 0,
    screen_share_modal: 0,
    kick_user_modal: 0,
    switch_tab_modal: 0,
    share_entire_screen_modal: 0,
    internet_turn_off_modal: 0,
  });

  const { effectiveType, isOnline } = useNetworkStatus(modalsRef);

  const uploadQueueRef = useRef<any[]>([]);
  const bufferQueueRef = useRef<any[]>([]);
  const tempQueue = useRef<any[]>([]);
  const chunkBatchNumber = useRef(0);
  const chunkGenerationSequenceRef = useRef(0);
  const isProcessingRef = useRef(false);
  const totalDataSizeRef = useRef(0);
  const remainingChunksRef = useRef(0);
  const totalChunksCreatedRef = useRef(0);
  const totalChunksCreatedRef2 = useRef(0);
  const [openDataUploadModal, setOpenDataUploadModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
  const [slowInternetFlag, setSlowInternetFlag] = useState(false);

  const [fileName, setFileName] = useState<Record<string | number, string>>({});
  const [isUploading, setIsUploading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning" | null>(null);
  const [alertMessage, setAlertMessage] = useState("");


  const screenRecordingFileNameRef = useRef<string>("");

  const screenRecordingUploadIdRef = useRef<string>("");

  const s3ChunksPartRef = useRef<any[]>([]);

  const b2ChunksHashRef = useRef<string[]>([]);

  const completeFileUploadRef = useRef<boolean>(false);

  const latestUploadedPartRef = useRef<any[]>([]);

  const allowedMimeTypes = process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST
    ? process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST.split(",") // Split the string into an array
    : [];

  const activityRef = useRef<USER_ACTIVITY>({
    clicks: 0,
    keystrokes: 0,
    scrolls: 0,
    totalMinutes: 0,
    perMinuteData: [],
  });

  const anitCheatingEventRef = useRef<any>({
    [AntiCheatingColumnName.cameraStopped]: [],
    [AntiCheatingColumnName.screenStopped]: [],
    [AntiCheatingColumnName.inspectElementEvents]: [],
    [AntiCheatingColumnName.facesDetected]: [],
    [AntiCheatingColumnName.fullscreenEvents]: [],
    [AntiCheatingColumnName.tabSwitchEvents]: [],
  });

  const currentusertoken = JSON.parse(
    localStorage.getItem("userdetails") as string
  );
  const sortnofetched = useAppSelector((state) => state?.tests?.fetchedsortno);

  if (sortnofetched && sortnofetched.length > 0) {
    const data = encryptData(sortnofetched);
    sessionStorage.setItem("sortnofetched_session", data);
  }
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  const recorderInstance: any = useAppSelector(
    (state) => state.screenrecord.recorder
  );

  const fetchedsetting = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );

  const fetcheddata = useAppSelector((state) => state?.tests?.fetcheddata);

  const fetchedsuccess = useAppSelector(
    (state) => state?.tests?.fetchedsuccess
  );

  const integrationIdRef = useRef<any>(fetchedoverviewdata?.integration_id);
  const integrationAppIdRef = useRef<any>(
    fetchedoverviewdata?.integration_app_id
  );

  console.log("Checking values of integration_id", integrationIdRef.current);
  console.log("Checking values of integration_app_id", integrationAppIdRef.current);

  const storeAntiCheatingEvent = (data:any) => {
    const {
      columnName,
      eventType,
      start,
      end,
      multiple_faces,
      no_faces,
      time,
    } = data;

    const fieldData = anitCheatingEventRef.current[columnName] || [];

    const currentTime = time;
  
    if (end) {
      // If 'end' is provided, update the last entry's 'end' time
      const lastEntry = fieldData[fieldData?.length - 1];
      if (lastEntry && !lastEntry?.end && lastEntry?.start) {
        lastEntry.end = currentTime;
      }
    } else if (start) {
      // If 'start' is provided, determine if a new entry should be added
      const lastEntry = fieldData[fieldData?.length - 1];
  
      const isRecentEntryMatching = (entry:any) => {
        if (eventType === "faces") {
          if (multiple_faces && entry?.multiple_faces === true && !entry?.end) {
            return true;
          }
          if (no_faces && entry?.no_faces === true && !entry?.end) {
            return true;
          }
        } else if (eventType === "camera") {
          if (!entry?.end) {
            return true;
          }
        }
        return false;
      };
  
      if (eventType === "faces" && (multiple_faces || no_faces)) {
        // Handle 'faces_detected' events
        if (!lastEntry || !isRecentEntryMatching(lastEntry)) {
          if (multiple_faces) {
            fieldData?.push({
              multiple_faces: true,
              start: currentTime,
              end: null,
            });
          } else if (no_faces) {
            fieldData?.push({ no_faces: true, start: currentTime, end: null });
          }
        }
      } else if (
        ["screen", "inspect", "full_screen", "tab_switch"].includes(eventType)
      ) {
        // Handle generic events like screen, inspect, full_screen, tab_switch
        fieldData?.push({ start: currentTime, end: null });
      } else if (eventType === "camera") {
        // Handle 'camera_stopped' events
        if (!lastEntry || !isRecentEntryMatching(lastEntry)) {
          fieldData?.push({ start: currentTime, end: null });
        }
      }
    }

    anitCheatingEventRef.current[columnName] = fieldData;
  };

  function incremenentScreeAndCameraCount(fieldName: string) {


    if (tokenid) {
      return null;
    }
    const API_URL = `/getexamdata/increment-count/${id}`;

    axios.post(API_URL, { field: fieldName })
      .then(response => {
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const sendLog = (data: any, for_proctor?: boolean, test_taker_email?: string) => {
    if (tokenid) {
      return null;
    }

    let payload: LogsPayload = {
      data,
      cryptvalue: process.env.REACT_APP_CRYPTVALUELOG,
    };

    if (for_proctor) {
      payload = {
        ...payload,
        type: "live_proctor_logs",
      };
    }
    axios.post("/logger/insertlog", { ...payload }).then((res) => { });
  };

  //function to open modal for tab switch events.
  const openmodalForWindowSwitch = () => {
    setIsModalOpen(true);
    modalsRef.current.switch_tab_modal = 1;
  };

  const [isModalOpenCamera, setIsModalOpenCamera] = useState(false);

  //function to open modal for camera sharing / image capture stop events.
  const openmodalForCamera = async (type?: string) => {
    setIsModalOpenCamera(true);
    modalsRef.current.camera_modal = 1;
    let data: AntiCheatingEventsType = {
      eventType: EventType.Faces,
      test_assessment_id: id ?? "",
      start: true,
      time: returnUTCDate(),
      columnName: AntiCheatingColumnName.facesDetected,
    };
    // console.log("facesDetected", facesDetected);
    if (facesDetected === 0) {
      data = {
        ...data,
        no_faces: true,
      }
    } else if (facesDetected > 1) {
      data = {
        ...data,
        multiple_faces: true,
      }
    }
    try {
      if ((data?.no_faces || data?.multiple_faces) && facesEndRef.current === 0 && !tokenid) {
        if (!isSubmitting) {
          storeAntiCheatingEvent(data);
          // await antiCheatingEvents(data);
        }
        facesEndRef.current = 1;
      }
    } catch (error) {
      console.log("Error occured while sending anticheating events for camera:", error);
    }
    if (type === "closed") {
      // incremenentScreeAndCameraCount("camera")
    }

  };

  const closemodalForCamera = async () => {
    setIsModalOpenCamera(false);
    modalsRef.current.camera_modal = 0;
    const data = {
      eventType: EventType.Faces,
      test_assessment_id: id ?? "",
      end: true,
      time: returnUTCDate(),
      columnName: AntiCheatingColumnName.facesDetected,
    };
    try {
      if (!isSubmitting && !tokenid) {
        storeAntiCheatingEvent(data);
        // await antiCheatingEvents(data);
      }
      facesEndRef.current = 0;
    } catch (error) {
      console.log("Error occured while sending anticheating events for camera:", error);
    }
  };

  const [isModalOpenScreen, setIsModalOpenScreen] = useState(false);

  //function to open modal for screen sharing / recording stop events.
  const openmodalForScreen = async () => {
    setIsModalOpenScreen(true);
    modalsRef.current.screen_share_modal = 1;
    // incremenentScreeAndCameraCount("screen")
    const data = {
      eventType: EventType.Screen,
      test_assessment_id: id ?? "",
      end: true,
      time: returnUTCDate(),
      columnName: AntiCheatingColumnName.screenStopped,
    };
    try {
      if (!isSubmitting && !tokenid) {
        storeAntiCheatingEvent(data);
        // await antiCheatingEvents(data);
      }
    } catch (error) {
      console.log("Error occured while sending anticheating events for screen:", error);
    }
  };


  //function to start recording again when user stops it inside the test, 
  //this will combine media and audio streams and pass it to MediaRecorder API which records the user screen.
  const handleStartRecording = async () => {
    try {
      // Get screen stream

      const { frameRate, videoBitsPerSecond, audioBitsPerSecond, screenWidth, screenHeight, devicePixelRatio } = getOptimizedStreamConstraints(fetchedoverviewdata);

      const data = `Client Side --> User started recording in test --> Screen Width ${screenWidth}, Screen Height ${screenHeight}, pixelRatio ${devicePixelRatio}, Frame Rate ${frameRate}, Video Bits Per Second ${videoBitsPerSecond}, Audio Bits Per Second ${audioBitsPerSecond} for user ${localstoragedata.email}`;

      console.log(data);
      sendLog(data, false);

      let screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          //@ts-ignore
          displaySurface: "monitor",
          width: { ideal: 960 },
          height: { ideal: 540 },
          frameRate: { max: frameRate },
        },
      });

      // Check if the user has an audio input device
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioDevice = devices.some(
        (device) => device.kind === "audioinput"
      );

      let audioStream = null;

      // Get audio stream from microphone if available
      if (hasAudioDevice) {
        //@ts-ignore
        audioStream = await navigator?.mediaDevices?.getUserMedia({
          audio: true,
        });
      }

      // Combine the screen and audio streams
      let combinedStream = new MediaStream();
      [screenStream, audioStream].forEach((stream) => {
        //@ts-ignore
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => combinedStream.addTrack(track));
        }
      });

      const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

      console.log("isFirefox", isFirefox);
      
      // Exclude mimeType for Firefox
      const options = isFirefox
        ? {
          videoBitsPerSecond: videoBitsPerSecond,        // 500 kbps for video
          audioBitsPerSecond: audioBitsPerSecond         // 64 kbps for audio
        } 
        : {
          mimeType: 'video/webm; codecs=vp8',             // Use VP8 codec for other browsers
          videoBitsPerSecond: videoBitsPerSecond,        // 500 kbps for video
          audioBitsPerSecond: audioBitsPerSecond          // 64 kbps for audio
        };

      // const options = {
      //   mimeType: 'video/webm; codecs=vp8', // Use VP8 for compatibility
      //   videoBitsPerSecond: videoBitsPerSecond,        // 500 kbps for video
      //   audioBitsPerSecond: audioBitsPerSecond          // 64 kbps for audio
      // };

      let recorder: any = new MediaRecorder(combinedStream, options);

      // console.log("recorder combined Stream inside test", recorder);
      setRecorder(recorder);

      dispatch(handlestartrecording({ recordinstance: recorder }));

      setScreenShareLoading(true);

      let displaySurface = recorder?.stream?.getVideoTracks()[0]?.getSettings()?.displaySurface;

      const label = recorder.stream.getVideoTracks()[0].label;

      if (displaySurface === "monitor" || label === "Primary Monitor") {

        if (fetchedsetting?.allowscreenrecording == "true") {
          const response = await axios.post(`/getexamdata/generate-filename/${id}`);

          if (response) {
            setIsModalOpenScreen(false);
            setUserStoppedScreenSharing(false);
            screenRecordingFileNameRef.current = response.data.screenRecordingFileName;
            chunkBatchNumber.current++;
            modalsRef.current.screen_share_modal = 0;
            bufferQueueRef.current = [];
            tempQueue.current = [];
            totalChunksCreatedRef.current = 0;
            completeFileUploadRef.current = false;
            totalDataSizeRef.current = 0;
            sendLog(
              `${localstoragedata.email
              } user again started screen recording in test - ${new Date()}`,
              false,
            );
            const data = {
              eventType: EventType.Screen,
              test_assessment_id: id ?? "",
              start: true,
              time: returnUTCDate(),
              columnName: AntiCheatingColumnName.screenStopped,
            };
            try {
              if (!isSubmitting && !tokenid) {
                storeAntiCheatingEvent(data);
                // await antiCheatingEvents(data);
              }
            } catch (error) {
              console.log("Error occured while sending anticheating events for screen:", error);
            }
            return true;
          } else {
            const datapayload = {
              messages: "Some error occured while sharing, try to share again!",
              severity: "error",
            };
            dispatch(handleopensnackbar(datapayload));
          }
        } else {
          setIsModalOpenScreen(false);
          modalsRef.current.screen_share_modal = 0;
          sendLog(
            `${localstoragedata.email
            } user again started screen recording in test - ${new Date()}`,
            false,
          );
          const data = {
            eventType: EventType.Screen,
            test_assessment_id: id ?? "",
            start: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.screenStopped,
          };
          try {
            if (!isSubmitting && !tokenid) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for screen:", error);
          }
          return true;
        }
      } else {
        setDisplayEntireScreenModal(true);
        modalsRef.current.share_entire_screen_modal = 1;

        // Stop the recording
        recorder.stop();

        // Release tracks from both streams
        if (screenStream && screenStream.getTracks) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        if (audioStream && audioStream.getTracks) {
          audioStream.getTracks().forEach((track) => track.stop());
        }

        setRecorder("");
        return false;
      }
    } catch (error) {
      console.error("Error accessing user media:", error);
    } finally {
      setScreenShareLoading(false);
    }
  };


  useEffect(() => {
    if (tokenid) {
      const payload = {
        testid: tokenid,
      };
      dispatch(fetchdatapreview(payload));
    } else {

      const payload = {
        testid: id,
      };

      dispatch(fetchoverview(payload));
    }

    localStorage.removeItem("assement_info");

  }, [dispatch, id, tokenid]);

  //function to fetch session data
  const fetchSessionData = async () => {
    const response = await axios.get(`/getexamdata/getusersession/${id}`);
    if (response && response?.status === 200) {
      sessionId.current = response?.data?.data?.id;
      setautorecoverydata(response?.data?.data?.userAnswers ? JSON.parse(response?.data?.data?.userAnswers) : {});
      chunkBatchNumber.current = response?.data?.data?.currentFileIndex;
      screenRecordingFileNameRef.current = response?.data?.data?.screenRecordingFileName;
      latestUploadedPartRef.current = response?.data?.data?.cloudUploadData;
    }
  }

  //if sucess false when fetching overview data then redirect to test overview page or preview test page
  useEffect(() => {
    if (!fetchedoverviewdata.success && !tokenid) {
      window.location.href = `/testoverview/${id}`
    } else if (!fetchedoverviewdata.success && tokenid) {
      window.location.href = `/preview-testpage/${tokenid}`
    } else if (fetchedoverviewdata.success && !tokenid && fetchedoverviewdata?.allowed_to_take_test === false) {
      window.location.href = `/testoverview/${id}`
    }
  }, [fetchedoverviewdata]);

  //fetch session data like sessionId and user answers for test taken by user for existing session.
  useEffect(() => {
    if (fetchedoverviewdata.success && !tokenid && sortnofetched && sortnofetched.length > 0) {
      fetchSessionData();
    }
  }, [fetchedoverviewdata, sortnofetched])


  //fetch preview data on basis of tokenid (for preview of creator of test) 
  //or testid (for preview of test taken by user)
  useEffect(() => {
    if (tokenid) {
      const payload = {
        testid: tokenid,
      };
      dispatch(fetchdatapreview(payload));
    }
    else {
      if (fetchedoverviewdata?.allowed_to_take_test === true) {
        const payload = {
          testid: id,
        };
        dispatch(fetchdata(payload));
      }
    }

  }, []);


  // useEffect(() => {
  //   if (autorecoverydata != undefined || autorecoverydata !== "") {
  //     // autorecoverydata?.map((each: any) => {
  //     //   if (each?.user_answers) {
  //     //     setdataforrecover(JSON.parse(each?.user_answers));
  //     //   }
  //     // });
  //   }
  // }, [autorecoverydata]);

  //set answers to state when they exists in the database for users current session and test is not submitted.
  useEffect(() => {
    if (sortnofetched && sortnofetched.length > 0 && !tokenid) {
      setparticularvalue((prev: any) => ({ ...prev, ...autorecoverydata }));
    }
  }, [sortnofetched, autorecoverydata, tokenid]);

  useEffect(() => {
    if (typeof particularvalue === 'object' && Object?.keys(particularvalue)?.length > 0) {
      const data = encryptData(particularvalue);
      sessionStorage.setItem("user_answers", data);
    }
  }, [particularvalue])


  const [fetcheddatawhole, setfetcheddatawhole] = useState<any>([]);

  //redirect user to dashboard when he is not invited for the test.
  useEffect(() => {
    // alert(fetchedsuccess)
    if (fetchedsuccess == false) {
      window.location.href = "/home/dashboard";
      const datapayload = {
        messages: "You are not invited for this test",
        severity: "error",
      };
      dispatch(handleopensnackbar(datapayload));
    } else {
      setfetcheddatawhole(fetcheddata);
    }
  }, [fetcheddata]);

  //handler function to set answers to state for question type where codeapi, codemirror and ckeditor are used.
  const changehandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    name?: string,
    data?: any,
    type?: string
  ) => {
    setparticularvalue((prevValue: any) => {
      switch (type) {
        case "codeapi":
          return { ...prevValue, [name!]: data };
        case "codemirror":
          return { ...prevValue, [name!]: { source_code: data } };
        case "ckeditor":
          return { ...prevValue, [name!]: data };
        default:
          return { ...prevValue, [e.target.name]: e.target.value };
      }
    });
  };

  //handler function to handler check boxes clicking events and set answer to state. 
  const changehandlercheckbox = (e: any) => {
    if (e.target.checked == true) {
      setparticularvalue({
        ...particularvalue,
        [e.target.name]: {
          ...particularvalue[e.target.name],
          [e.target.value]: 1,
        },
      });
    } else if (e.target.checked == false) {
      setparticularvalue({
        ...particularvalue,
        [e.target.name]: {
          ...particularvalue[e.target.name],
          [e.target.value]: 0,
        },
      });
    }
  };

  //handler function to upload images, files for upload type of questions and set answer to state.
  const changehandlerimage = async (event: any) => {
    const file = event.target.files[0];

    // Validate file size (e.g., 2 MB)
    const maxSize = parseInt(process.env.REACT_APP_ALLOWED_MAX_FILE_SIZE || '2097152');
    if (file.size > maxSize) {
      setAlertType("error");
      setAlertMessage("File is too large. Maximum allowed size is 2MB.");
      setAlertSnackBarOpen(true);
      return;
    }

    // Validate mimeType (only allow image files)
    if (!allowedMimeTypes.includes(file.type)) {
      setAlertType("error");
      setAlertMessage("Please upload a valid image, PDF, DOCX, CSV, or Excel file.");
      setAlertSnackBarOpen(true);
      return;
    }

    const localstoragedata = JSON.parse(
      localStorage.getItem("userdetails") as string
    );

    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsUploading(true);
      const response = await axios.post(
        `/getexamdata/upload-file/${id}?useremail=${localstoragedata?.email}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        // console.log("File uploaded successfully:", response.data);
        setparticularvalue({
          ...particularvalue,
          [event.target.name]: encodeURIComponent(response.data.url),
        });
        setFileName((prevFileNames: any) => ({
          ...prevFileNames,
          [event.target.name]: file.name,
        }));
        setFileType((prevFileType: any) => ({
          ...prevFileType,
          [event.target.name]: file.type,
        }));
        // setFileName(file.name);
        setAlertType("success");
        setAlertMessage("File uploaded successfully.");
        setAlertSnackBarOpen(true);
      } else {
        setAlertType("error");
        setAlertMessage("File uploaded failed.");
        setAlertSnackBarOpen(true);
        setIsUploading(false);
        // console.log("File upload failed:", response.status);
      }


    } catch (error) {
      setAlertType("error");
      setAlertMessage("File uploaded failed.");
      setAlertSnackBarOpen(true);
      console.error("Error uploading file:", error);
    }finally{
      console.log("finally");
      setIsUploading(false);
    }
  };

  const [isVideoUploading, setIsVideoUploading] = useState(false);

  //handler function video upload for video type of questions and set answer to state.
  const changehandlervideo = async (blobdata: Blob, sortno: number) => {
    setIsVideoUploading(true);
    let data = new FormData();
    data.append("media_blob", blobdata);

    if (tokenid) {
      return null;
    }

    //over here save api and then store in database and the returned uuid here
    axios
      .post(`/getexamdata/savemedia/${id}`, data, {
        headers: {
          Authorization: `Bearer ${currentusertoken.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        // dispatch(handlecloseloader());

        if (res?.data?.media_uuid) {
          setIsVideoUploading(false);
          setparticularvalue({
            ...particularvalue,
            [sortno]: res?.data?.media_uuid,
          });
          setAlertType("success");
          setAlertMessage("Your video has recorded and uploaded successfully!");
          setAlertSnackBarOpen(true);
        }
      })
      .catch((err) => {
        // dispatch(handlecloseloader());
        console.error(err);

        const datapayload = {
          messages: err?.response?.data?.data?.error || err?.message,
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
      });
  };

  // for autosave after a time or whenever a answer is being written

  const [syncvalue, setsyncvalue] = React.useState(false);

  const [allvideoupload, setallvideoupload] = useState(false);

  //useEffect for checking if the test is being viewed in dev tools 
  //and if so then show inspect dev tools modal and send anticheating data
  useEffect(() => {
    if (fetchedsetting && location.pathname.includes("test") && sortnofetched && sortnofetched.length > 0 && !tokenid) {
      if ((fetchedsetting?.blockrightclick == "true" || fetchedsetting?.blockinspect == "true") && isDevToolsOpen) {
        setIsModalOpenInspectDevTools(true);
        modalsRef.current.inspect_modal = 1;
        (async () => {
          const data = {
            eventType: EventType.Inspect,
            test_assessment_id: id ?? '',
            start: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.inspectElementEvents,
          }
          try {
            if (!isSubmitting) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for inspect:", error);
          }
        })();
      } else {
        setIsModalOpenInspectDevTools(false);
        modalsRef.current.inspect_modal = 0;
        (async () => {
          const data = {
            eventType: EventType.Inspect,
            test_assessment_id: id ?? '',
            end: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.inspectElementEvents,
          }
          try {
            if (!isSubmitting) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for inspect:", error);
          }
        })();
      }
    }
  }, [isDevToolsOpen, fetchedsetting, sortnofetched])

  //function to save all the recordings
  function saveAllRecording() {
    const saveButtons = document.querySelectorAll(".saveRecordingButton");
    let clickedButtons = 0;

    if (saveButtons.length === 0) {
      console.warn("No elements with class 'saveRecordingButton' found.");
      return onclickhandler();
    }

    setIsVideoUploading(true);
    saveButtons.forEach((button) => {
      if (!isVideoUploading) {
        //@ts-ignore
        button.click();
        clickedButtons++;
        console.log(
          "🚀 ~ file: Testwrapper.tsx:290 ~ saveAllRecording ~ button:",
          clickedButtons,
          saveButtons.length
        );
        if (clickedButtons === saveButtons.length) {
          setallvideoupload(true);
        }
      }
    });
  }

  function onclickhandler() {
    handleShow();
  }

  //function to complete fileUpload to aws/wasabi/backblaze
  const uploadCompleteVideo = async (event: any) => {
    const UploadBaseURL =
      Number(integrationAppIdRef.current) === 5
        ? "/cloudupload/get-presigned-url-complete-upload-aws"
        : Number(integrationAppIdRef.current) === 7
        ? "/cloudupload/get-upload-url-complete-upload-b2"
        : Number(integrationAppIdRef.current) === 8
        ? "/cloudupload/get-presigned-url-complete-upload-wasabi"
        : "";

    if(Number(integrationAppIdRef.current) === 5 || Number(integrationAppIdRef.current) === 8){
      try {
        const getPresignedUrlResponse = await axios.post(
          `${UploadBaseURL}/${integrationIdRef.current}`,
          {
            organization_id: fetchedoverviewdata?.organization_id,
            test_id: fetchedoverviewdata?.testid,
            email: localstoragedata?.email?.toLowerCase(),
            video_name: event?.screenRecordingFileName,
            fileType: "video/webm",
          }
        );

        // {
        //   organization_id: fetchedoverviewdata?.organization_id,
        //   test_id: fetchedoverviewdata?.testid,
        //   email: localstoragedata?.email?.toLowerCase(),
        //   video_name: screenRecordingFileNameRef.current,
        //   fileType: "video/webm",
        // }
  
        const presignedUrl = getPresignedUrlResponse.data.url;

        console.log("presignedUrl whole file upload", decodeURIComponent(presignedUrl));

        console.log("temp queue length", tempQueue.current.length);
  
        const uploadResponse = await fetch(decodeURIComponent(presignedUrl), {
          method: 'PUT',
          headers: {
            'Content-Type': "video/webm",
          },
          body: event.data,
        });

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload file');
        }

        const existingItem = latestUploadedPartRef.current.find(
          (item) => item.fileIndex === event.currentFileIndex
        );

        if (existingItem) {
          existingItem.complete_uploaded = true;
        }else{
          latestUploadedPartRef.current.push({
            event,                 // Add the event
            fileIndex: event.currentFileIndex, // Store the fileIndex
            parts: [],
            complete_uploaded: true,
          });
        }
        console.log('File uploaded successfully! for aws or wasabi');
        return true;
      } catch (error) {
        console.error('Error uploading file for aws or wasabi:', error);
      }
    }else if(Number(integrationAppIdRef.current) === 7){
      try{
        const getUploadUrlResponse = await axios.post(
          `${UploadBaseURL}/${integrationIdRef.current}`,
          {
            organization_id: fetchedoverviewdata?.organization_id,
            test_id: fetchedoverviewdata?.testid,
            email: localstoragedata?.email?.toLowerCase(),
            video_name: event.screenRecordingFileName,
            fileId: screenRecordingUploadIdRef.current,
          }
        );
  
        const uploadUrl = getUploadUrlResponse.data.uploadUrl;
  
        const authorizationToken = getUploadUrlResponse.data.b2AuthorizationToken;

        const filePath = `${fetchedoverviewdata?.organization_id}/${fetchedoverviewdata?.testid}/${localstoragedata?.email?.toLowerCase()}/video/${screenRecordingFileNameRef.current}`;
        
        const arrayBuffer = await event.data.arrayBuffer();

        // Convert ArrayBuffer to Uint8Array for compatibility with sha1
        const uint8Array = new Uint8Array(arrayBuffer);

        // Calculate SHA-1 hash from the Uint8Array
        const hash = sha1(uint8Array);

        const uploadResponse = await fetch(decodeURIComponent(uploadUrl), {
          method: 'POST',
          headers: {
            'Authorization': authorizationToken,
            'Content-Type': 'video/webm',
            'X-Bz-File-Name': encodeURIComponent(filePath),
            'X-Bz-Content-Sha1': hash,
          },
          body: event.data,
        });

        // console.log("response", uploadResponse);

        if (!uploadResponse.ok) {
          throw new Error('Failed to upload file');
        }

        const existingItem = latestUploadedPartRef.current.find(
          (item) => item.fileIndex === event.currentFileIndex
        );

        if (existingItem) {
          existingItem.complete_uploaded = true;
        }else{
          latestUploadedPartRef.current.push({
            event,               
            fileIndex: event.currentFileIndex,
            parts: [],
            complete_uploaded: true,
          });
        }

        console.log('File uploaded successfully! for b2');

        return true;
      }catch(error){
        console.error('Error uploading file for b2:', error);
      }
    }
     
  }

  //initial function to submit test data and upload chunks to server when test is submitted.
  async function submithandler(issubmittedvalue?: number, event?: any) {
    if (tokenid) {
      return null;
    }

    console.log("totalParts before test submitted", totalChunksCreatedRef.current);
    console.log("remainingParts before test submitted", remainingChunksRef.current);

    sendLog(`Total parts before test submitted --> created: ${totalChunksCreatedRef.current}, remaining: ${remainingChunksRef.current} for user ${localstoragedata.email?.toLowerCase()}`, false);

    if (issubmittedvalue) {
      setIsSubmitting(true);
      dispatch(handleopenloader());

      if (
        fetchedsetting?.randomphotos == "true" ||
        fetchedoverviewdata?.isvideoanswer ||
        fetchedsetting?.live_proctoring_camera == "true"
      ) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          end: true,
          time: returnUTCDate(),
          columnName: AntiCheatingColumnName.cameraStopped,
        };

        try {
          storeAntiCheatingEvent(dataForCamera);
          // await antiCheatingEvents(dataForCamera);
        } catch (error) {
          console.log("Error occurred while sending anti-cheating events for camera or screen:", error);
          dispatch(handlecloseloader());
        }
      }

      if (
        recorderInstance &&
        (fetchedsetting?.allowscreenrecording == "true" ||
          fetchedsetting?.live_proctoring_screen == "true") &&
        !tokenid
      ) {
        const dataForScreen = {
          eventType: EventType.Screen,
          test_assessment_id: id ?? "",
          end: true,
          time: returnUTCDate(),
          columnName: AntiCheatingColumnName.screenStopped,
        };

        try {
          storeAntiCheatingEvent(dataForScreen);
          // await antiCheatingEvents(dataForScreen);
          recorderInstance.stop();
          dispatch(handlestoprecording());
        } catch (error) {
          console.log("Error occurred while sending anti-cheating events for camera or screen:", error);
          dispatch(handlecloseloader());
        }
      }
    }

    // Execute `handleSubmissionLogic` first
    try {
      await antiCheatingEvents(anitCheatingEventRef.current, id, sessionId.current);
      await handleSubmissionLogic(issubmittedvalue, event ?? null);
    } catch (error) {
      console.log("Error occurred while sending anti-cheating events or handling submission logic:", error);
      dispatch(handlecloseloader());
    }

    // Skip screen recording block if the condition doesn't apply
    if (Number(issubmittedvalue) === 1 && fetchedsetting?.allowscreenrecording == "true") {
      // const mediaStream = recorderInstance.stream;
      //   const tracks = mediaStream.getTracks();

      //   for (const track of tracks) {
      //     if (track.kind === "video") {
      //       track.addEventListener("ended", () => {
      //         console.log("Recording stopped by user");
      //         recorderInstance.stop();
      //       });
      //       break;
      //     }
      //   }
      console.log("Processing...");

      new Promise(resolve => setTimeout(resolve, 10000));

      console.log("Processing completed!");
      
      if (integrationIdRef.current === null || integrationIdRef.current === -1) {
        console.log("Integration is not enabled");
        if(bufferQueueRef.current.length > 0){
          console.log("Test is being submitted, uploading the remaining buffer...");
          sendLog(`Test is submitted and uploading remaning chunks in buffer to server for user ${localstoragedata.email?.toLowerCase()}`, false);

          // Combine all remaining chunks into a single Blob
          const remainingBlob = new Blob(
            bufferQueueRef.current.map((item: any) => item.event.data),
            { type: "video/webm" }
          );
  
          // Create a combined event object with the merged Blob
          const remainingEvent = { event: { data: remainingBlob, sequenceNumber: sequenceNumber.current ,currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current } };
  
          // Push the remaining event into the upload queue
          uploadQueueRef.current.push(remainingEvent);
  
          // Clear the buffer
          bufferQueueRef.current = [];
  
          //Update total chunks created
          totalChunksCreatedRef.current += 1;

          totalChunksCreatedRef2.current += 1;
        } 
      }else {
        console.log("Integration is enabled");
        if (totalChunksCreatedRef.current >= 2) {
          const log = "Test is submitted and uploading data to cloud storage where parts are more than or equal to 2..."
          console.log(log);
          sendLog(`${log} for user ${localstoragedata?.email?.toLowerCase()}`, false);
          if(bufferQueueRef.current.length > 0){
            const log2 = "Test is being submitted, uploading the remaining buffer chunks..."
            console.log(log2);
            sendLog(`${log2} for user ${localstoragedata?.email?.toLowerCase()}`, false);
  
            // Combine all remaining chunks into a single Blob
            const remainingBlob = new Blob(
              bufferQueueRef.current.map((item: any) => item.event.data),
              { type: "video/webm" }
            );
    
            // Create a combined event object with the merged Blob
            const remainingEvent = { event: { data: remainingBlob, sequenceNumber: sequenceNumber.current ,currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current } };
    
            // Push the remaining event into the upload queue
            uploadQueueRef.current.push(remainingEvent);
    
            // Clear the buffer
            bufferQueueRef.current = [];
    
            //Update total chunks created
            totalChunksCreatedRef.current += 1;

            totalChunksCreatedRef2.current += 1;
          } 
        }else{
          const log3 = "🚀🚀 Test is being submitted, only less two parts are generated..."
          console.log(log3);
          sendLog(`${log3} for user ${localstoragedata?.email?.toLowerCase()}`, false);
          const log4 = "uploading complete video file to cloud"
          console.log(log4);
          sendLog(`${log4} for user ${localstoragedata?.email?.toLowerCase()}`, false);
          
          const remainingTempBlob = new Blob(
            tempQueue.current.map((item: any) => item.event.data),
            { type: "video/webm" }
          );
  
          // Create a combined event object with the merged Blob
          const remainingEvent = { event: { data: remainingTempBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current } };
  
          // Push the remaining event into the upload queue
          uploadQueueRef.current.push(remainingEvent);
  
          // Clear the buffer
          tempQueue.current = [];
  
          //Update total chunks created
          totalChunksCreatedRef.current += 1;

          totalChunksCreatedRef2.current += 1;
  
          completeFileUploadRef.current = true;
        }
      }

      const remainingChunks = uploadQueueRef.current.length;
      console.log("totalParts after test is submitted", totalChunksCreatedRef.current);
      console.log("remainingParts after test is submitted", remainingChunks);

      if (remainingChunks > 0) {
        // Handle remaining chunks
        const log5 = "Chunks are remaining in queue which is being uploaded now..."
        console.log(log5);
        sendLog(`${log5} for user ${localstoragedata?.email?.toLowerCase()}`, false);
        handleRemainingChunks(remainingChunks, issubmittedvalue, event ?? null);
        return;
      } else {
        const formData = new FormData();

        let payload: any = {
          sessionId: sessionId.current,
          screen_recording_uploaded: 1,
        }

        formData.append("payload", JSON.stringify(payload));

        await retryRequest(`getexamdata/savesessionrecording/${id}`, formData);
      }
      await finalizeSubmission();
    } else if (Number(issubmittedvalue) === 1 && fetchedsetting?.allowscreenrecording == "false") {
      await finalizeSubmission();
    }
  }

  //function to handle remaining chunks after test is submitted
  async function handleRemainingChunks(remainingChunks: number, issubmittedvalue?: number, event?: any) {
    dispatch(handlecloseloader());

    const totalChunks = totalChunksCreatedRef2.current;
    const progressPercentage = ((totalChunks - remainingChunks) / totalChunks) * 100;

    // Show modal with circular progress
    setUploadProgress(progressPercentage);
    setOpenDataUploadModal(true);

    try {
      // Process chunks with a time limit
      await processChunksWithTimeout(issubmittedvalue);
    } catch (error) {
      console.error("Error while uploading chunks:", error);
    } finally {
      setOpenDataUploadModal(false);
      console.log("Proceeding to further processing when chunks are uploaded after the threshold time limit");
      dispatch(handleopenloader());
      await finalizeSubmission();
    }
  }

  //function to process chunks with timeout
  async function processChunksWithTimeout(issubmittedvalue?: number) {
    // const TIME_LIMIT = 10000;
    const TIME_LIMIT = process.env.REACT_APP_CHUNK_UPLOAD_WAIT_INTERVAL ?? 3600000
    const startTime = Date.now();

    // while (uploadQueueRef.current.length > 0) {
    //   // Check time limit
    //   const elapsedTime = Date.now() - startTime;
    //   if (elapsedTime > Number(TIME_LIMIT)) {
    //     console.error("Time limit exceeded for uploading chunks");
    //     break;
    //   }

    //   // Process the next chunk
    //   // const { event } = uploadQueueRef.current.shift(); // Safely retrieve next chunk
    //   // await uploadChunk(event, issubmittedvalue);
    // }

    await processQueue(issubmittedvalue, TIME_LIMIT, startTime);

    while (Date.now() - startTime < Number(TIME_LIMIT) && uploadQueueRef.current.length > 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    const formData = new FormData();

    let payload: any = {
      sessionId: sessionId.current,
      screen_recording_uploaded: 1,
    }

    formData.append("payload", JSON.stringify(payload));

    await retryRequest(`getexamdata/savesessionrecording/${id}`, formData);

    // Final check after timeout or completion
    if (uploadQueueRef.current.length > 0) {
      const log8 = "Final check: Adding slow internet flag";
      console.log(log8);
      sendLog(`${log8} for user ${localstoragedata?.email?.toLowerCase()}`, false);

      // const progressPercentage = ((totalChunksCreatedRef.current - uploadQueueRef.current.length) / totalChunksCreatedRef.current) * 100;

      let data: any = {
        fileIndex: chunkBatchNumber.current,
        payload: [totalChunksCreatedRef.current, uploadQueueRef.current.length, formatFileSize(totalDataSizeRef.current ?? 0)],
      };

      let payload = {
        tableName: 'test_taker_sessions',
        columnName: 'logs',
        type: LogType.SCREEN_RECORDING_CHUNK,
        data,
      }

      await retryRequest(`getexamdata/store-data-for-logs/${sessionId.current}`, payload);


      addSlowInternetFlag(sendLog, localstoragedata.email, id ?? 'Not Test ID found', uploadQueueRef.current.length);
    }
  }

 
  //function to sent test data to server when submithandler is called.
  async function handleSubmissionLogic(issubmittedvalue?: number, event?: any) {
    // Fetch session data
    const sortnofetched_session = sessionStorage.getItem("sortnofetched_session") as string;
    const user_answers_session = sessionStorage.getItem("user_answers") as string ?? null;
    const data = decryptData(sortnofetched_session);

    let user_answers;
    if (user_answers_session) {
      user_answers = decryptData(user_answers_session);
    }

    const cleanedParticularValue = cleanObject(particularvalue);
    const cleanedUserAnswers = user_answers ? cleanObject(user_answers) : null;

    let payload: any = {
      issubmitted: issubmittedvalue,
      answers: typeof cleanedParticularValue === "object" && Object?.keys(cleanedParticularValue)?.length > 0
        ? cleanedParticularValue
        : cleanedUserAnswers
          ? cleanedUserAnswers
          : cleanedParticularValue,
      geolocationlatitude: geolocationdata?.current?.geolocationlatitude,
      geolocationlongitude: geolocationdata?.current?.geolocationlongitude,
      accuracy: geolocationdata?.current?.accuracy,
      allotedquestions: sortnofetched && sortnofetched.length > 0 ? sortnofetched : data,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };

    if (Number(issubmittedvalue) === 1 && fetchedsetting?.allowscreenrecording == "true") {
      payload = {
        ...payload,
        screen_recording_uploaded: uploadQueueRef.current.length === 0 ? 1 : 0,
      }
    }

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // if (
    //   recorderInstance &&
    //   fetchedsetting?.allowscreenrecording === "true" &&
    //   event &&
    //   event.data
    // ) {
    //   const checksum = calculateChecksum(event.data);
    //   formData.append("screenRecording", event.data);
    //   formData.append("screenRecordingChecksum", JSON.stringify(checksum));
    //   formData.append("sequenceNumber", JSON.stringify(sequenceNumber.current));
    //   sequenceNumber.current++;
    // }

    // Add modal payload data
    formData.append("modals_payload", JSON.stringify({
      camera_modal: modalsRef.current.camera_modal === 1 ? "open" : "closed",
      full_screen_modal: modalsRef.current.full_screen_modal === 1 ? "open" : "closed",
      inspect_modal: modalsRef.current.inspect_modal === 1 ? "open" : "closed",
      screen_share_modal: modalsRef.current.screen_share_modal === 1 ? "open" : "closed",
      kick_user_modal: modalsRef.current.kick_user_modal === 1 ? "open" : "closed",
      switch_tab_modal: modalsRef.current.switch_tab_modal === 1 ? "open" : "closed",
      entire_screen_modal: modalsRef.current.share_entire_screen_modal === 1 ? "open" : "closed",
      internet_turn_off_modal: modalsRef.current.internet_turn_off_modal === 1 ? "open" : "closed",
    }));

    try {
      await retryRequest(`getexamdata/saveapi/${id}`, formData);
      dispatch(handlecloseloader());

      // if (response?.data?.issubmitted === 1) {
      //   finalizeSubmission(response.data);
      // }

      setsyncvalue(false);
    } catch (e: any) {
      dispatch(handlecloseloader());
      handleSubmissionError(e);
    }
  }

  //function to finalize submission and redirect user to outside assessment and socket disconnect
  async function finalizeSubmission() {
    if (!tokenid) {
      const data = `Client Side --> Test with id ${id} submitted for user with email ${localstoragedata.email}`;
      sendLog(data, false);
    }
    if (socketinstance) {
      socketinstance.emit("user-disconnect", {
        assessment_id_secret: id,
        msg: "user has submitted or moved away from assessment",
        userremoved: 1,
        is_reload: true,
        userid: userId,
        organization_id: fetchedoverviewdata?.organization_id ?? 0,
      });
    }

    if (
      fetchedsetting.allowscreenrecording == "true" ||
      fetchedsetting?.randomphotos == "true" ||
      fetchedoverviewdata?.isfileuploadanswer ||
      ((fetchedoverviewdata?.ai_interview == 1 ||
        fetchedoverviewdata?.isvideoanswer) &&
        Object.values(particularvalue).length)
    ) {

      if (fetchedsetting?.allowscreenrecording == "true") {
        if(integrationAppIdRef.current && (Number(integrationAppIdRef.current) === 5 || Number(integrationAppIdRef.current) === 8)){
          // try {
          //   const completeUploadBaseURL = Number(integrationAppIdRef.current) === 5 ? "/cloudupload/complete-upload-aws" : "/cloudupload/complete-upload-wasabi";
          //   // await axios.post(`${completeUploadBaseURL}/${integrationIdRef.current}`, {
          //   //   uploadId: screenRecordingUploadIdRef.current,
          //   //   organization_id: fetchedoverviewdata?.organization_id,
          //   //   test_id: fetchedoverviewdata?.testid,
          //   //   email: localstoragedata?.email?.toLowerCase(),
          //   //   video_name: screenRecordingFileNameRef.current,
          //   //   parts: s3ChunksPartRef.current,
          //   // })
          // } catch (error) {
          //   console.log("Error occured while merging chunks on AWS", error);
          // }
          const completeUploadBaseURL = Number(integrationAppIdRef.current) === 5
            ? "/cloudupload/complete-upload-aws"
            : "/cloudupload/complete-upload-wasabi";

          // Iterate through each item in the latestUploadedPartRef.current array
          for (const uploadPart of latestUploadedPartRef.current) {
            const log111 = `Uploading part ${uploadPart.event.currentFileIndex} and part array is ${JSON.stringify(uploadPart)} for user ${localstoragedata.email?.toLowerCase()}`;
            console.log(log111);
            sendLog(log111, false);
            if (uploadPart.complete_uploaded === false) {
              try {
                // Prepare the parts array for the current upload part
                const parts = uploadPart.parts;  // Assuming the parts are stored in the `parts` field of each item
  
                // Send the complete upload request for the current part
                await axios.post(`${completeUploadBaseURL}/${integrationIdRef.current}`, {
                  uploadId: uploadPart.event.uploadId,  // Use the uploadId from the event
                  organization_id: fetchedoverviewdata?.organization_id,
                  test_id: fetchedoverviewdata?.testid,
                  email: localstoragedata?.email?.toLowerCase(),
                  video_name: uploadPart.event.screenRecordingFileName,
                  parts: parts,
                });
  
                console.log(`Successfully completed upload for ${uploadPart.event.screenRecordingFileName}`);
  
              } catch (error) {
                console.error(`Error occurred while completing upload for ${uploadPart.event.screenRecordingFileName}:`, error);
              }
            }
          }

        }else if (integrationAppIdRef.current && Number(integrationAppIdRef.current) === 7) {
          // try {
          //   await axios.post(`/cloudupload/complete-upload-b2/${integrationIdRef.current}`, {
          //     fileId: screenRecordingUploadIdRef.current,
          //     organization_id: fetchedoverviewdata?.organization_id,
          //     test_id: fetchedoverviewdata?.testid,
          //     email: localstoragedata?.email?.toLowerCase(),
          //     video_name: screenRecordingFileNameRef.current,
          //     partSha1Array: b2ChunksHashRef.current,
          //   })
          // } catch (error) {
          //   console.log("Error occured while merging chunks on B2", error);
          // }
          for (const uploadPart of latestUploadedPartRef.current) {
            const log112 = `Uploading part ${uploadPart.event.currentFileIndex} and part array is ${JSON.stringify(uploadPart)} for user ${localstoragedata.email?.toLowerCase()}`;
            console.log(log112);
            sendLog(log112, false);
            if (uploadPart.complete_uploaded === false) {
              try {
                // Prepare the data for the current part
                const partSha1Array = uploadPart.parts;  // Assuming the parts are stored in the `parts` field of each item
          
                // Send the complete upload request for the current part
                await axios.post(`/cloudupload/complete-upload-b2/${integrationIdRef.current}`, {
                  fileId: uploadPart.event.uploadId,  // Use the uploadId from the event
                  organization_id: fetchedoverviewdata?.organization_id,
                  test_id: fetchedoverviewdata?.testid,
                  email: localstoragedata?.email?.toLowerCase(),
                  video_name: uploadPart.event.screenRecordingFileName, // Use the screenRecordingFileName from the event
                  partSha1Array: partSha1Array,  // Send the part hashes for this file
                });
          
                console.log(`Successfully completed upload for ${uploadPart.event.screenRecordingFileName}`);
          
              } catch (error) {
                console.error(`Error occurred while completing upload for ${uploadPart.event.screenRecordingFileName}:`, error);
              }
            }
          }
        }
      }
      dispatch(handlestoprecording());
      dispatch(handlecloseloader());
      // window.location.href = `/dashboard/attemptedtest/step3/${id}`;
      navigate(`/dashboard/attemptedtest/step3/${id}`);
    } else {
      // window.location.href = `/dashboard/attemptedseries?testid=${id}&isfeedback=1`;
      navigate(`/dashboard/attemptedseries?testid=${id}&isfeedback=1`);
    }
  }

  //function to handle submission error
  function handleSubmissionError(error: AxiosError) {
    const datapayload = {
      messages: error?.response?.data?.data?.error || error?.message,
      severity: "error",
    };

    console.log("Error occurred for save API", error);
    dispatch(handleopensnackbar(datapayload));
  }

  // useEffect(() => {
  //   if (fetchedoverviewdata?.isvideoanswer && (typeof particularvalue === 'object' && Object?.keys(particularvalue)?.length > 0) && !tokenid) {
  //     console.log("going inside this 1");
  //     submithandler();
  //   }
  // }, [fetchedoverviewdata?.isvideoanswer, tokenid, particularvalue])

  //useEffect for anticheating events for screen and camera start inside test.
  useEffect(() => {
    if (sortnofetched && sortnofetched.length > 0 && !tokenid) {
      console.log("going inside this anticheating event");
      let data: AntiCheatingEventsType = {
        test_assessment_id: id ?? "",
        start: true,
        time: returnUTCDate(),
      }

      if (
        fetchedsetting?.randomphotos == "true" ||
        fetchedoverviewdata?.isvideoanswer ||
        fetchedsetting?.live_proctoring_camera == "true"
      ) {
        try {
          data = {
            ...data,
            eventType: EventType.Camera,
            columnName: AntiCheatingColumnName.cameraStopped,
          };
          (async () => {
            try {
              if (!isSubmitting) {
                storeAntiCheatingEvent(data);
                // await antiCheatingEvents(data);
              }
            } catch (error) {
              console.log("Error occured while sending anticheating events for camera:", error);
            }
          })();
        } catch (error) {
          console.error(
            "Error occured while sending anticheating events for camera:",
            error
          );
        }
      }

      if (
        fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true"
      ) {
        try {
          data = {
            ...data,
            eventType: EventType.Screen,
            columnName: AntiCheatingColumnName.screenStopped,
          };
          (async () => {
            try {
              if (!isSubmitting) {
                storeAntiCheatingEvent(data);
                // await antiCheatingEvents(data);
              }
            } catch (error) {
              console.log("Error occured while sending anticheating events for screen:", error);
            }
          })();
        } catch (error) {
          console.error(
            "Error occured while sending anticheating events for screen recording:",
            error
          );
        }
      }
    }
  }, [sortnofetched, tokenid])


  //useEffect for listening to events for screen recording stopped,send that log to backend and also disconnecting socket when user stops recording.
  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") &&
      !tokenid
    ) {
      try {
        
        recorderInstance.onstop = async () => {
          if(!isSubmitting){
            setUserStoppedScreenSharing(true);
            console.log("Processing...");
            new Promise(resolve => setTimeout(resolve, 10000));
            console.log("Processing completed!");
            dispatch(handlestoprecording());
          }
        };
     
        recorderInstance.start(Number(process.env.REACT_APP_RECORDER_INSTANCE_INTERVAL ?? 60000));
        // console.log(Number(process.env.REACT_APP_RECORDER_INSTANCE_INTERVAL ?? 60000))
        // recorderInstance.start(60000);

        const mediaStream = recorderInstance.stream;
        const tracks = mediaStream.getTracks();

        for (const track of tracks) {
          if (track.kind === "video") {
            track.addEventListener("ended", () => {
              console.log("Recording stopped by user");
              recorderInstance.stop();
            });
            break;
          }
        }
      } catch (err) {
        sendLog(
          `${localstoragedata.email
          } user stopped screen recording - ${new Date()}`,
          false
        );

        // openmodalForScreen();
      }
    }
  }, [
    recorderInstance,
    fetchedsetting?.allowscreenrecording,
    fetchedsetting?.live_proctoring_screen,
    tokenid,
    isSubmitting
  ]);

  // console.log("checking screen stopped considition", !tokenid && (fetchedsetting?.allowscreenrecording == 'true' || fetchedsetting?.live_proctoring_screen == 'true') && userStoppedScreenSharing && !isSubmitting && !recorderInstance);
  
  const finalExecutionForStopScreenRecording = async() => {
    console.log("🧹🧹 clearing the upload/file Id, S3/WASABI Parts array, B2 Hash array and setting sequence number back to 1")
    screenRecordingUploadIdRef.current = "";
    s3ChunksPartRef.current = [];
    b2ChunksHashRef.current = [];
    sequenceNumber.current = 1;

    // console.log("clearing S3ChunksPartRef", s3ChunksPartRef.current);
  }

  useEffect(() => {
    if (!tokenid && (fetchedsetting?.allowscreenrecording == 'true' || fetchedsetting?.live_proctoring_screen == 'true') && userStoppedScreenSharing && !isSubmitting && !recorderInstance) {
      console.log("reached here ✅");
      const _localstoragedata = JSON.parse(
        localStorage.getItem("userdetails") as string
      );

      let _userId: any;

      const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
        query: {
          token: _localstoragedata?.token,
          user_type: "test_taker_user",
        },
      });

      setsocketinstance2(socket);

      (async function () {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/authuser/getuserid/${_localstoragedata?.email}`
          );

          _userId = response.data.user_id || 0;
        } catch (error) {
          console.error("Error fetching user ID:", error);
        }
      })();

      sendLog(
        `${localstoragedata.email
        } user stopped screen recording - ${new Date()}`,
        false
      );

      const peerId = JSON.parse(sessionStorage.getItem("userPeerID") as string);

      if (peerId) {
        socket.emit("remove-peer-id", {
          peerID: peerId,
          assessment_id_secret: id,
          userid: _userId ?? 0,
        });
      }

      socket.emit("user-disconnect", {
        msg: "user stopped screen recording stop",
        userremoved: 1,
        userid: _userId ?? 0,
        is_reload: false,
        assessment_id_secret: id,
        organization_id: fetchedoverviewdata?.organization_id ?? 0,
      });

      openmodalForScreen();
      setsocketinstance(null);

      if (fetchedsetting?.allowscreenrecording == 'true') {
        if (integrationIdRef.current === null || integrationIdRef.current === -1) {
          console.log("Integration is not enabled");
          console.log("Buffer Queue length", bufferQueueRef.current.length);
          console.log("🚀🚀 temp queue length", tempQueue.current.length);
          setTimeout(() => {
            if (bufferQueueRef.current.length > 0) {
              console.log("User stopped the screen recording, uploading the remaining buffer...");
  
              // Combine all remaining chunks into a single Blob
              const remainingBlob = new Blob(
                bufferQueueRef.current.map((item: any) => item.event.data),
                { type: "video/webm" }
              );
  
              // Create a combined event object with the merged Blob
              const remainingEvent = { event: { data: remainingBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current } };
  
              // Push the remaining event into the upload queue
              uploadQueueRef.current.push(remainingEvent);
  
              // Clear the buffer
              bufferQueueRef.current = [];
  
              //Update total chunks created
              totalChunksCreatedRef.current += 1;
  
              totalChunksCreatedRef2.current += 1;
  
              sequenceNumber.current++;
  
              processQueue();
            }
          },2000);
          finalExecutionForStopScreenRecording();
        } else {
          console.log("Integration is enabled");
          //here totalChunksCreatedRef after introduction of multipart points to total created parts
          if (totalChunksCreatedRef.current >= 2) {
            (async function () {
              if (bufferQueueRef.current.length > 0) {
                console.log("User stopped the screen recording, uploading the remaining buffer...");
                // Combine all remaining chunks into a single Blob
                const remainingBlob = new Blob(
                  bufferQueueRef.current.map((item: any) => item.event.data),
                  { type: "video/webm" }
                );
          
                // Create a combined event object with the merged Blob
                const remainingEvent = { event: { data: remainingBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current } };
          
                // Push the remaining event into the upload queue
                uploadQueueRef.current.push(remainingEvent);
          
                // Clear the buffer
                bufferQueueRef.current = [];
          
                // Update total chunks created
                totalChunksCreatedRef.current += 1;

                totalChunksCreatedRef2.current += 1;

                sequenceNumber.current++;

                // Wait for the processQueue to finish
                await processQueue();
              }
              await finalExecutionForStopScreenRecording();
              // Now execute the complete upload logic
            })();
          
          } else {
            console.log("User stopped the screen recording, only less two parts are generated...");

            if (tempQueue.current.length > 0) {
              console.log("tempe queue length 🚀🚀", tempQueue.current.length);
              const remainingTempBlob = new Blob(
                tempQueue.current.map((item: any) => item.event.data),
                { type: "video/webm" }
              );

              // Create a combined event object with the merged Blob
              const remainingEvent = { data: remainingTempBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current };

              (async function () {
                await uploadCompleteVideo(remainingEvent);
                await finalExecutionForStopScreenRecording();
              })();

              // Clear the buffer
              bufferQueueRef.current = [];

              //Clear the temp
              tempQueue.current = [];
            }else{
              setTimeout(() => {
                console.log("buffer queue length 🚀🚀", bufferQueueRef.current.length);
              
                const remainingBufferBlob = new Blob(
                  bufferQueueRef.current.map((item: any) => item.event.data),
                  { type: "video/webm" }
                );

                // Create a combined event object with the merged Blob
                const remainingEvent = { data: remainingBufferBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current };
                const log113 = `User stopped the screen recording, complete file upload intiating uploading the remaining buffer ${remainingBufferBlob} for user ${localstoragedata.email?.toLowerCase()}`;
                console.log(log113);
                sendLog(log113, false);
                (async function () {
                  await uploadCompleteVideo(remainingEvent);
                  await finalExecutionForStopScreenRecording();
                })();

                // Clear the buffer
                bufferQueueRef.current = [];

                //Clear the temp
                tempQueue.current = [];
              }, 2000);
            }

          }
        }

        chunkGenerationSequenceRef.current = 0;
        // uploadQueueRef.current = [];
        // bufferQueueRef.current = [];
      }
    }
  }, [recorderInstance, userStoppedScreenSharing, isSubmitting, fetchedsetting, tokenid])
  

  //useEffect for sending logs to server when users recorderInstance is initialized.
  useEffect(() => {
    if (recorderInstance && fetchedsetting?.allowscreenrecording == "true" && !tokenid) {
      const localstoragedata = JSON.parse(
        localStorage.getItem("userdetails") as string
      );

      // console.log("🚀🚀 recorderInstance", recorderInstance);
      const data = `Client Side ->> Recorder instance initialized ${recorderInstance} for user ${localstoragedata?.email}`;
      sendLog(data, false);
    }

  }, [recorderInstance, fetchedsetting?.allowscreenrecording, tokenid])

  const MAX_RETRIES = 3;
  const UPLOAD_INTERVAL = 1000; // Delay between retries in ms
  const MIN_DATA_SIZE = 6000000; // 6 MB

  //function to submit chunk to server
  const submitChunk = async (event?: any) => {
    try {
      const formData = new FormData();

      // console.log("session Id", sessionId.current);
      let payload: any = {
        sessionId: sessionId.current
      }

      formData.append("payload", JSON.stringify(payload));

      if (
        fetchedsetting?.allowscreenrecording == "true" &&
        event &&
        event.data
      ) {
        const checksum = calculateChecksum(event.data);
        formData.append("screenRecording", event.data);
        formData.append("screenRecordingChecksum", JSON.stringify(checksum));
        formData.append("sequenceNumber", JSON.stringify(event.sequenceNumber));
        formData.append("currentFileIndex", JSON.stringify(event.currentFileIndex));
        // formData.append("sequenceNumber", JSON.stringify(sequenceNumber.current));
        // formData.append("currentFileIndex", JSON.stringify(chunkBatchNumber.current));
        // sequenceNumber.current++;

        await retryRequest(`getexamdata/savesessionrecording/${id}`, formData);
      }
    } catch (error) {
      console.log("Error occured while submitting chunk remaining chunks:", error);
    }
  }

  //function to upload chunk to awsS3 or wasabiS3
  const uploadChunkToS3 = async (event?: any, presignedUrl?: any) => {
    try {
      // Upload the chunk using the PUT method with the presigned URL
      const response = await fetch(decodeURIComponent(presignedUrl), {
        method: "PUT",
        headers: {
          'Content-Type': 'video/webm',
        },
        body: event.data, // Attach the chunk data
      });
      
      if (!response.ok) {
        sendLog(`Failed to upload chunk to AWS S3 / Wasabi S3 From Frontend: ${JSON.stringify(response)}`, false);
        throw new Error("Failed to upload chunk to AWS S3 / Wasabi S3");
      }

      // Get the ETag from the response headers (use 'etag' from the headers object)
      const eTag = response.headers.get("etag");
      return eTag; // Return the ETag of the uploaded chunk
    } catch (error) {
      console.error("Error uploading chunk to AWS S3 / Wasabi S3:", error);
      sendLog(`Error uploading chunk to AWS S3 / Wasabi S3: ${JSON.stringify(error)}`, false);
      throw error; // Rethrow to let the caller handle retry logic
    }
  };
  
  //function to get presigned url for awsS3 or wasabiS3
  const submitChunkToAWS = async (event?: any) => {
    try {
      const preSignedBaseURL = Number(integrationAppIdRef.current) === 5 ? "/cloudupload/get-presigned-url-aws" : "/cloudupload/get-presigned-url-wasabi";
      
      const getPresignedUrlResponse = await axios.post(
        `${preSignedBaseURL}/${integrationIdRef.current}`,
        {
          organization_id: fetchedoverviewdata?.organization_id,
          test_id: fetchedoverviewdata?.testid,
          email: localstoragedata?.email?.toLowerCase(),
          video_name: event.screenRecordingFileName,
          partNumber: event.sequenceNumber,
          uploadId: event.uploadId,
        }
      );

      const presignedUrl = getPresignedUrlResponse.data.url;

      // console.log("presignedUrl multipart", decodeURIComponent(presignedUrl));

      // Upload the chunk to S3 using the pre-signed URL
      const eTag = await uploadChunkToS3(event, presignedUrl);

      // Save the part information with ETag and part number
      // s3ChunksPartRef.current.push({
      //   ETag: eTag,
      //   PartNumber: event.sequenceNumber,
      // });

     // Find the matching object in latestUploadedPartRef
    const existingItem = latestUploadedPartRef.current.find(
      (item) => item.fileIndex === event.currentFileIndex
    );

    let tempPartsArray;

    if (existingItem) {
      // If matching object is found, update the event and push new ETag and sequence number
      existingItem.event = event; // Update the event
      existingItem.parts.push({
        ETag: eTag,            // Add the ETag to the parts
        PartNumber: event.sequenceNumber, // Add the sequence number to the parts
      });
      tempPartsArray = existingItem.parts;
    } else {
      // If no matching object is found, create a new one with the event and ETag
      latestUploadedPartRef.current.push({
        event,                 // Add the event
        fileIndex: event.currentFileIndex, // Store the fileIndex
        parts: [{
          ETag: eTag,           // Store the ETag
          PartNumber: event.sequenceNumber, // Store the sequence number
        }],
        complete_uploaded: false,
      });
      tempPartsArray = [{
        ETag: eTag,           // Store the ETag
        PartNumber: event.sequenceNumber, // Store the sequence number
      }];
    }

     console.log("Updated latestUploadedPartRef", latestUploadedPartRef.current);

     sendLog(`Upload PARTS ARRAY AWS/WASABI: ${JSON.stringify(latestUploadedPartRef.current)}`, false);
      // sequenceNumber.current++;

      let data: any = {
        fileIndex: event.currentFileIndex,
        payload: [event.uploadId, event.screenRecordingFileName, tempPartsArray]
      };

      let payload = {
        tableName: 'test_taker_sessions',
        columnName: 'logs',
        type: LogType.SCREEN_RECORDING_DIRECT_CLOUD,
        data,
      }

      await retryRequest(`getexamdata/store-data-for-logs/${sessionId.current}`, payload);

      // Return success (true) or any specific result
      return true;
    } catch (error) {
      console.log("Error occurred while submitting chunk to AWS:", error);
      return false;
    }
  };

  //function to upload chunk to b2
  const uploadChunkToB2 = async (event?: any, uploadUrl?: any, authToken?:any) => {
    try {
      const arrayBuffer = await event.data.arrayBuffer();

      // Convert ArrayBuffer to Uint8Array for compatibility with sha1
      const uint8Array = new Uint8Array(arrayBuffer);

      // Calculate SHA-1 hash from the Uint8Array
      const hash = sha1(uint8Array);

      // console.log(hash);

      await fetch(decodeURIComponent(uploadUrl), {
        method: 'POST',
        headers: {
          'Authorization': authToken,
          'X-Bz-Part-Number': String(event.sequenceNumber),
          'X-Bz-Content-Sha1': hash,
        },
        body: event.data,
      });

      return hash;
    } catch (error) {
      sendLog(`Failed to upload chunk to B2 From Frontend: ${JSON.stringify(error)}`, false);
      console.log("Error occurred while uploading chunk to B2:", error);
      throw error;
    }
  };

  const submitChunkToB2 = async (event?: any) => {
    try {
      const getUploadUrlResponse = await axios.post(
        `/cloudupload/get-upload-url-b2/${integrationIdRef.current}`,
        {
          organization_id: fetchedoverviewdata?.organization_id,
          test_id: fetchedoverviewdata?.testid,
          email: localstoragedata?.email?.toLowerCase(),
          video_name: event.screenRecordingFileName,
          fileId: event.uploadId,
        }
      );

      const uploadUrl = getUploadUrlResponse.data.uploadUrl;

      const authorizationToken = getUploadUrlResponse.data.b2AuthorizationToken;

      // console.log("uploadUrl", decodeURIComponent(uploadUrl));

      // Upload the chunk to S3 using the pre-signed URL
      const b2ChunkHash = await uploadChunkToB2(event, uploadUrl, authorizationToken);

      b2ChunksHashRef.current.push(b2ChunkHash);
      // Save the part information with ETag and part number
      // sequenceNumber.current++;

      // Check if an entry already exists for the given fileIndex (or currentFileIndex)
      const existingItem = latestUploadedPartRef.current.find(
        (item) => item.fileIndex === event.currentFileIndex
      );

      let tempPartsArray;

      if (existingItem) {
        // If matching object is found, update the event and push the new b2ChunkHash into the parts array
        existingItem.event = event; // Update the event
        existingItem.parts.push(b2ChunkHash); // Add the new chunk hash to parts
        tempPartsArray = existingItem.parts;
      } else {
        // If no matching object is found, create a new entry with the current event and b2ChunkHash
        latestUploadedPartRef.current.push({
          event,                 // Add the event
          fileIndex: event.currentFileIndex, // Store the fileIndex
          parts: [b2ChunkHash], // Store the b2ChunkHash in the parts array
          complete_uploaded: false,
        });
        tempPartsArray = [b2ChunkHash];
      }

      console.log("Updated latestUploadedPartRef", latestUploadedPartRef.current);

      sendLog(`Upload PARTS ARRAY B2: ${JSON.stringify(latestUploadedPartRef.current)}`, false);


      let data: any = {
        fileIndex: event.currentFileIndex,
        payload: [event.uploadId, event.screenRecordingFileName, tempPartsArray]
      };

      let payload = {
        tableName: 'test_taker_sessions',
        columnName: 'logs',
        type: LogType.SCREEN_RECORDING_DIRECT_CLOUD,
        data,
      }

      await retryRequest(`getexamdata/store-data-for-logs/${sessionId.current}`, payload);

      return true;
    } catch (error) {
      console.log("Error occurred while submitting chunk to B2:", error);
      return false;
    }
  };

  //function to upload chunk which will pass the chunk to submitChunk fUNCTIONS above
  const uploadChunk = async (event: any, retries = 0, issubmittedvalue?: number): Promise<boolean> => {
    try {
      // console.log("issubmittedvalue", issubmittedvalue);
      // if (issubmittedvalue && Number(issubmittedvalue) === 1) {
      //   await submitChunkWhenTestIsSubmitted(event);
      // }else {
      //   await submithandler(0, event);
      // }
      if (integrationAppIdRef.current && (Number(integrationAppIdRef.current) === 5 || Number(integrationAppIdRef.current) === 8)) {
        if (completeFileUploadRef.current === true && issubmittedvalue && Number(issubmittedvalue) === 1) {
          await uploadCompleteVideo(event);
        }else{
          await submitChunkToAWS(event);
        }
      }else if(integrationAppIdRef.current && Number(integrationAppIdRef.current) === 7){
        if (completeFileUploadRef.current === true && issubmittedvalue && Number(issubmittedvalue) === 1) {
          await uploadCompleteVideo(event);
        }else{
          await submitChunkToB2(event);
        }
      }else {
        await submitChunk(event);
      }
      return true;
    } catch (error) {
      if (retries < MAX_RETRIES) {
        console.log(`Retrying upload... Attempt ${retries + 1}`);
        const data = `Client Side ->> Screen recording chunk upload failed with chunk size ${formatFileSize(event.data.size ?? 0)}, attempt no. ${retries + 1}  for user ${localstoragedata?.email}`;
        sendLog(data, false);
        await new Promise((resolve) => setTimeout(resolve, UPLOAD_INTERVAL));
        return uploadChunk(event, retries + 1, issubmittedvalue);
      } else {
        console.log("Failed to upload chunk after maximum retries");
        return false;
      }
    }
  };

  //function to process queue for uploading chunks
  const processQueue = async (issubmittedvalue?: number, TIME_LIMIT?: any, startTime?: any) => {
    if (isProcessingRef.current) return; // Prevent multiple simultaneous processing
    isProcessingRef.current = true;

    try {
      console.log("IN process queue checking uploadQueueRef.current.length", uploadQueueRef.current.length);
      while (uploadQueueRef.current.length > 0) {
        console.log("inside while loop uploading chunks in queue", uploadQueueRef.current.length);

        if (issubmittedvalue && Number(issubmittedvalue) === 1) {
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime > Number(TIME_LIMIT)) {
            const log6 = "Time limit exceeded for uploading chunks some chunks may be lost 😢";
            console.log(log6);
            sendLog(`${log6} for user ${localstoragedata?.email?.toLowerCase()}`, false);
            break;
          }
        }

        const { event } = uploadQueueRef.current[0]; // Peek at the first chunk without removing it

        // Validate that the event object is not empty or invalid
        if (!event || Object.keys(event).length === 0) {
          const data = `Client Side ->> Empty or invalid chunk detected. Skipping. for user ${localstoragedata?.email}`;
          console.log(data);
          sendLog(data, false);
          uploadQueueRef.current.shift(); // Remove the invalid chunk
          continue; // Skip to the next chunk
        }

        // Wait until the current chunk is fully processed (uploaded or retried successfully)
        // console.log("processing queue issubmittedvalue", issubmittedvalue);
        const isUploaded = await uploadChunk(event, 0, issubmittedvalue);

        if (isUploaded) {
          console.log("😃 Chunk successfully uploaded. Removing from the queue.");
          uploadQueueRef.current.shift(); // Remove the successfully uploaded chunk

          // Update remaining chunks and progress
          remainingChunksRef.current = uploadQueueRef.current.length;
          const totalChunks = totalChunksCreatedRef2.current;
          setUploadProgress(
            ((totalChunks - remainingChunksRef.current) / totalChunks) * 100
          );

          let data: any = {
            fileIndex: chunkBatchNumber.current,
            payload: [totalChunksCreatedRef.current, uploadQueueRef.current.length, formatFileSize(totalDataSizeRef.current ?? 0)]
          };

          let payload = {
            tableName: 'test_taker_sessions',
            columnName: 'logs',
            type: LogType.SCREEN_RECORDING_CHUNK,
            data,
          }

          await retryRequest(`getexamdata/store-data-for-logs/${sessionId.current}`, payload);


        } else {
          const log7 = "Chunk upload ultimately failed after retries 😢.";
          console.log(log7);
          sendLog(`${log7} for user ${localstoragedata?.email?.toLowerCase()}`, false);
          break; // Stop processing further if a chunk fails completely
        }
      }
    } catch (error) {
      console.error("Error during queue processing:", error);
    } finally {
      isProcessingRef.current = false; // Reset processing flag
    }
  }; 

  //function to validate chunk for metadata
  async function validateChunkForMetadata(event: any): Promise<boolean> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const bytes = new Uint8Array(arrayBuffer);
        // console.log("🚀🚀 chunk bytes", bytes);

        // Check for metadata or header in the chunk
        if (hasVideoHeaders(bytes)) {
          resolve(true);
        } else {
          // console.log("Chunk is missing required metadata/headers.");
          resolve(false);
        }
      };

      reader.onerror = () => {
        console.log("Failed to read chunk for metadata validation.");
        resolve(false);
      };

      // Ensure `event.data` is a valid `Blob` before processing
      if (event?.data instanceof Blob && event.data.size > 0) {
        // Read the first few bytes (headers are usually within the first 100 bytes)
        reader.readAsArrayBuffer(event.data.slice(0, 100)); // Use full Blob instead of `.data`
      } else {
        console.log("Invalid data in event:", event.data);
        resolve(false); // Handle invalid chunk scenario
      }
    });
  }

  // Validate headers for MP4/WebM chunks
  function hasVideoHeaders(bytes: Uint8Array): boolean {
    const ftyp = [0x66, 0x74, 0x79, 0x70]; // MP4 header
    const ebml = [0x1A, 0x45, 0xDF, 0xA3]; // WebM header

    // Check if the header appears at the very beginning of the bytes
    const isMP4Header = ftyp.every((value, index) => bytes[index] === value);
    const isWebMHeader = ebml.every((value, index) => bytes[index] === value);

    return isMP4Header || isWebMHeader;
  }

  const checkIntegrationWorking = async () => {
    if (integrationIdRef.current !== -1 || integrationIdRef.current !== null) {
      console.log("calling api for integration health check");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getexamdata/integration-health-check/${id}`,
        {
          orgId: fetchedoverviewdata?.organization_id,
          integration_id: integrationIdRef.current,
          isSession: true,
        }
      );
     
      if (response) {
        integrationIdRef.current = response.data.integration_id;
        integrationAppIdRef.current = response.data.integration_app_id;
      }else{
        integrationIdRef.current = -1;
        integrationAppIdRef.current = null;
      }
    }
  }

  useEffect(() => {
    if (
      recorderInstance &&
      fetchedsetting?.allowscreenrecording == "true" &&
      !tokenid
    ) {
      recorderInstance.ondataavailable = async (event: any) => {
        const newDataSize = event.data.size;
        totalDataSizeRef.current += newDataSize;
        chunkGenerationSequenceRef.current++;
        
        console.log("Sequence Number: ", sequenceNumber.current);

        if ((integrationIdRef.current !== -1 || integrationIdRef.current !== null) && chunkGenerationSequenceRef.current === 1) {
          await checkIntegrationWorking();
          console.log("Integration check done: ", integrationIdRef.current, integrationAppIdRef.current);
        }
        
        if (!userStoppedScreenSharing) {
          console.log("data available after recording stopped");
          
          const data = `Client Side ->> Screen recording chunk data created ${event?.data} and chunk size is ${formatFileSize(newDataSize ?? 0)} and total file size is ${formatFileSize(totalDataSizeRef.current ?? 0)} for user ${localstoragedata?.email}`;
          console.log(data);
          sendLog(data, false);

          //🚀🚀 This part is a later implementation in which we are sending chunks directly to S3 aws / Backblaze B2 cloud storage
          if (integrationAppIdRef.current && (Number(integrationAppIdRef.current) === 5 || Number(integrationAppIdRef.current) === 8) && chunkGenerationSequenceRef.current === 1) {
            console.log("🚀🚀 integration aws/wasabi s3 upload");

            const uploadBaseURL = Number(integrationAppIdRef.current) === 5 ? "/cloudupload/start-upload-aws" : "/cloudupload/start-upload-wasabi";

            const response = await axios.post(`${uploadBaseURL}/${integrationIdRef.current}`, {
              organization_id: fetchedoverviewdata?.organization_id,
              test_id: fetchedoverviewdata?.testid,
              email: localstoragedata?.email?.toLowerCase(),
              video_name: screenRecordingFileNameRef.current,
            });

            if (response) {
              screenRecordingUploadIdRef.current = response.data.uploadId;
              // console.log("response for cloudupload start", response);
            }
          }else if (integrationAppIdRef.current && Number(integrationAppIdRef.current) === 7 && chunkGenerationSequenceRef.current === 1) {
            console.log("🚀🚀 integration backblaze b2 upload");

            const response = await axios.post(`/cloudupload/start-upload-b2/${integrationIdRef.current}`, {
              organization_id: fetchedoverviewdata?.organization_id,
              test_id: fetchedoverviewdata?.testid,
              email: localstoragedata?.email?.toLowerCase(),
              video_name: screenRecordingFileNameRef.current,
            });

            if (response) {
              screenRecordingUploadIdRef.current = response.data.fileId;
              // console.log("response for cloudupload start", response);
            }
          }

          try {
            const hasMetadata = await validateChunkForMetadata(event);
            console.log("🚀🚀 hasMetadata", hasMetadata);
            if (chunkGenerationSequenceRef.current === 1) {
              const warning = `Initial Chunk generated with number ${chunkGenerationSequenceRef.current} has metadata -->> ${hasMetadata}. size of ${formatFileSize(event.data.size ?? 0)}  for user ${localstoragedata?.email}`;
              console.log(warning);
              sendLog(warning, false);
            }
          } catch (error) {
            console.log("Error during metadata validation:", error);
          }

          // Add the current chunk to the buffer
          bufferQueueRef.current.push({ event });

          
          if(totalChunksCreatedRef.current <= 2){
            tempQueue.current.push({ event });
          }

          const bufferTotalSize = bufferQueueRef.current.reduce(
            (total: number, chunk: any) => total + chunk.event.data.size,
            0
          );

          // Check if the threshold is met or exceeded
          if (bufferTotalSize >= MIN_DATA_SIZE) {
            const log9 = "🙌 Threshold met, combining and uploading buffered chunks.";
            console.log(`${log9} for user ${localstoragedata?.email?.toLowerCase()}`);

            // Combine buffered chunks into a single Blob
            const combinedBlob = new Blob(bufferQueueRef.current.map((item: any) => item.event.data), { type: "video/webm" });

            // Create a combined event object with the merged Blob
            const combinedEvent = { event: { data: combinedBlob, sequenceNumber: sequenceNumber.current, currentFileIndex: chunkBatchNumber.current, screenRecordingFileName: screenRecordingFileNameRef.current, uploadId: screenRecordingUploadIdRef.current } };

            // Push the combined event into the upload queue
            uploadQueueRef.current.push(combinedEvent);

            // Update total chunks created
            totalChunksCreatedRef.current += 1;

            totalChunksCreatedRef2.current += 1;

            // screenRecordingFileNameRefCopy.current = screenRecordingFileNameRef.current;
            // screenRecordingUploadIdRefCopy.current = screenRecordingUploadIdRef.current;
            
            // Reset the buffer and data size
            bufferQueueRef.current = [];

            remainingChunksRef.current = uploadQueueRef.current.length;

            let dataPayload: any = {
              fileIndex: chunkBatchNumber.current,
              payload: [totalChunksCreatedRef.current, uploadQueueRef.current.length, formatFileSize(totalDataSizeRef.current ?? 0)],
            };

            let payload = {
              tableName: "test_taker_sessions",
              columnName: "logs",
              type: LogType.SCREEN_RECORDING_CHUNK,
              data: dataPayload,
            };

            try {
              await retryRequest(
                `getexamdata/store-data-for-logs/${sessionId.current}`,
                payload
              );
            } catch (error) {
              console.log("Error in retryRequest:", error);
            }

             
            sequenceNumber.current++;

            console.log("sequence number update for next part upload", sequenceNumber.current);
            processQueue(); // Process the newly added combined chunk(s)
          } else {
            console.log(
              "Buffer threshold not met, chunk added to buffer. Total buffer size:",
              formatFileSize(bufferTotalSize)
            );
          }
        }
      };
    }
  }, [recorderInstance, fetchedsetting?.allowscreenrecording, tokenid, userStoppedScreenSharing]);

  //useEffect for submitting test data at 20 seconds interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchedsuccess) {
        if (sortnofetched.length > 0 && !isSubmitting) {
          // console.log("going inside this 2");
          submithandler();
          antiCheatingEvents(anitCheatingEventRef.current, id, sessionId.current);
        }
      }
    }, 20000); // 20000 milliseconds = 20 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [fetchedsuccess, sortnofetched, savetimer, isSubmitting, submithandler]);


  //useEffect for submitting test data after test is fetched
  useEffect(() => {
    const isEffectRun = sessionStorage.getItem("isEffectRun");
    if ((sortnofetched && sortnofetched.length > 0) && !isSubmitting && !isEffectRun) {
      submithandler();
      sessionStorage.setItem("isEffectRun", "true");
    }
  }, [sortnofetched, isSubmitting]);

  // useEffect(() => {
  //   if (
  //     !isSubmitting &&
  //     ((fetchedsetting?.allowscreenrecording === "true" && !recorderInstance) || (fetchedsetting?.live_proctoring_screen === "true" && !recorderInstance))
  //   ) {
  //     const datapayload = {
  //       messages: "Screen recording is required for this test",
  //       severity: "info",
  //     };
  //     dispatch(handleopensnackbar(datapayload));
  //     window.location.href = "/dashboard/assigned";
  //   }
  // }, [
  //   recorderInstance,
  //   navigate,
  //   isSubmitting,
  //   fetchedsetting?.allowscreenrecording,
  //   fetchedsetting?.live_proctoring_screen
  // ]);

  //dispatching answered values to redux store
  useEffect(() => {
    dispatch(answeredvalues(particularvalue));
  }, [particularvalue, dispatch]);

  //anticheating

  //block right click for anti cheating
  fetchedsetting?.blockrightclick == "true" &&
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });


  //block copy and paste for anti cheating
  const blockCopyAndPasteHandler = (e: any) => {
    if (fetchedsetting?.copynpaste == "true") {
      setAlertType("error");
      setAlertMessage("Copy & Paste is not allowed for this test!");
      setAlertSnackBarOpen(true);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  //block F12 key and Ctrl+Shift+I for inspecting test
  if (fetchedsetting?.blockinspect == "true") {
    document.onkeydown = function (e) {
      // disable F12 key

      if (e.key === "F12") {
        e.preventDefault();
      } else if (e.ctrlKey && e.shiftKey && e.key === "I") {
        // Prevent Ctrl+Shift+I
        return false;
      } else if (e.key === "Fn") {
        return false;
      }
    };
  }


  const myRef = useRef<any>(null);
  //submit modal
  const [show, setShow] = useState(false);


  const handleClose = () => {
    setShow(false);

    scroller.scrollTo(`1-id`, {
      duration: 0,
      delay: 100,
      smooth: true,
      offset: -120, // Scrolls to element - 120 pixels down the page
    });
  };


  const handleShow = () => setShow(true);

  //function to send user location to server
  function sendposition(position: any) {
    geolocationdata.current = {
      geolocationlatitude: position.coords.latitude,
      geolocationlongitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
    }
  }

  //function to get user location
  function getLocation() {
    const options = {
      enableHighAccuracy: true,
    };

    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(sendposition, error, options);
    }
  }

  //api call for updating location events for anti cheating
  useEffect(() => {
    if (fetchedsetting?.islocation == "true") {
      getLocation();
    }
  }, [fetchedsetting]);

  function error(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }


  //api call for updating tab switch events
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true" && fetchedsetting?.no_switch_tabs_allowed == "true") {
      window.onblur = async function () {
        if (tokenid) {
          return null;
        }
        if (fetchedsetting?.no_switch_tabs_allowed == "true") {
          const data = {
            eventType: EventType.TabSwitch,
            test_assessment_id: id ?? "",
            start: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.tabSwitchEvents,
          };
          try {
            if (!isSubmitting) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for tabswitch:", error);
          }
          openmodalForWindowSwitch();
        }
      };
      window.onfocus = async function () {
        if (tokenid) {
          return null;
        }
        const data = {
          eventType: EventType.TabSwitch,
          test_assessment_id: id ?? "",
          end: true,
          time: returnUTCDate(),
          columnName: AntiCheatingColumnName.tabSwitchEvents,
        };
        try {
          if (!isSubmitting) {
            storeAntiCheatingEvent(data);
            // await antiCheatingEvents(data);
          }
        } catch (error) {
          console.log("Error occured while sending anticheating events for tabswitch:", error);
        }
      };
    }
  }, [fetchedsetting?.anti_cheating]);

  //api call for updating mouse leave events
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true" && !isSubmitting) {
      document.addEventListener("mouseleave", function (event) {
        if (
          event.clientY <= 0 ||
          event.clientX <= 0 ||
          event.clientX >= window.innerWidth ||
          event.clientY >= window.innerHeight
        ) {
          if (tokenid) {
            return null;
          }
          if (sessionId.current === null || sessionId.current === 0) {
            return null;
          }
          axios
            .post(`getexamdata/mouseout/${id}`, { sessionId: sessionId.current })
            .then((res) => { })
            .catch((err) => console.error(err));
        }
      });
    }
  }, [fetchedsetting?.anti_cheating, isSubmitting]);


  // const [isFullscreen, setIsFullscreen] = useState(false);

  //api call for updating fullscreen events for anti cheating
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true" && fetchedsetting?.fullscreen == "true") {
      const handleResize = async () => {

        if (tokenid) {
          return null;
        }

        const innerHeight = window.innerHeight;
        if (!localStorage.getItem("assement_info")) {
          const encrypted = encryptData(innerHeight);
          localStorage.setItem("assement_info", encrypted);
        }

        const decryptedHeight = decryptData(localStorage.getItem("assement_info")) || 0;
        if (innerHeight > decryptedHeight) {
          const encrypted = encryptData(innerHeight);
          localStorage.setItem("assement_info", encrypted);
        }

        // eslint-disable-next-line no-restricted-globals
        const isFullscreen = innerHeight === decryptData(localStorage.getItem("assement_info"));

        if (!isFullscreen) {
          // setIsFullscreen(false);
          setIsModalOpenresize(true);
          modalsRef.current.full_screen_modal = 1;
          const data = {
            eventType: EventType.FullScreen,
            test_assessment_id: id ?? "",
            start: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.fullscreenEvents,
          };
          try {
            if (!isSubmitting) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for fullscreen:", error);
          }
        } else if (isFullscreen) {
          // setIsFullscreen(true);
          setIsModalOpenresize(false);
          modalsRef.current.full_screen_modal = 0;
          const data = {
            eventType: EventType.FullScreen,
            test_assessment_id: id ?? "",
            end: true,
            time: returnUTCDate(),
            columnName: AntiCheatingColumnName.fullscreenEvents,
          };
          try {
            if (!isSubmitting) {
              storeAntiCheatingEvent(data);
              // await antiCheatingEvents(data);
            }
          } catch (error) {
            console.log("Error occured while sending anticheating events for fullscreen:", error);
          }
        }
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [fetchedsetting, id]);

  const intl = useIntl();

  //peerjs

  const [webcamRef, setwebcamRef] = useState(null);

  //function to connect peer to server when user starts screen recording and camera for live proctoring
  const connectPeer = useCallback(async () => {
    if (tokenid) {
      return null;
    }
    if (recorderInstance) {
      const localstoragedata = JSON.parse(
        localStorage.getItem("userdetails") as string
      );
      const token = localstoragedata?.token;
      let peerID: any = null;

      if (peerInstance) {
        console.log("Destroying old peer instance...");
        peerInstance.destroy();
      }

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/authuser/getuserid/${localstoragedata.email}`
        )
        .then((res) => {
          let userid = res.data.user_id || 0;
          setUserid(userid);

          if (socketinstance) {
            socketinstance.disconnect();
          }

          if (socketinstance2) {
            socketinstance2.disconnect();
          }

          const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
            query: {
              token: token,
              user_type: "test_taker_user"
            },
          });
          setsocketinstance(socket);

          const mypeer = new Peer({
            host: "peer.mtesthub.com",
            port: 443,
            secure: true,
            path: "/mtesthub",
            config: {
              iceServers: [
                { urls: 'stun:stun.l.google.com:19302' },
                { urls: 'stun:stun1.l.google.com:19302' },
                { urls: 'stun:stun2.l.google.com:19302' },
                { urls: 'stun:stun3.l.google.com:19302' },
                { urls: 'stun:stun4.l.google.com:19302' },
                {
                  urls: 'turn:82.112.250.249:3478',
                  username: 'mtestUTurn',
                  credential: 'jhruryhu391225#1vc@oP'
                },
              ]
            }
          });

          setPeerInstance(mypeer);

          mypeer.on("open", (peerid) => {
            peerID = peerid;
            sessionStorage.setItem("userPeerID", JSON.stringify(peerid))
            console.log("My peer ID is:", peerid);
            socket.emit("client join", {
              assessment_id_secret: id,
              ttu_id: userid,
              peerid,
            });

            if (fetchedoverviewdata?.organization_id) {
              socket.emit("organization_event", {
                assessment_id_secret: id,
                organization_id: fetchedoverviewdata?.organization_id
              });
            }
          });

          // When receiving a call from another peer
          mypeer.on("call", (call) => {
            // Set the received call instance
            console.log("->>> Call event received");

            setPeerCallInstance(call);

            // Get the stream (presumably from recorderInstance.stream)
            let combinedStream: any = null;
            const stream = recorderInstance.stream;


            if (stream && stream.getTracks() && webcamRef) {
              combinedStream = new MediaStream([
                //@ts-ignore
                ...webcamRef?.current?.stream?.getTracks(),
                ...stream.getTracks(),
              ]);
            } else if (stream && stream.getTracks()) {
              combinedStream = new MediaStream([...stream.getTracks()]);
            } else if (webcamRef) {
              combinedStream = new MediaStream([
                //@ts-ignore
                ...webcamRef?.current?.stream?.getTracks(),
              ]);
            }

            if (combinedStream) {
              console.log(
                "🚀 ~ file: trackerIndex.js:600 ~ socket.on ~ combinedStream:",
                combinedStream
              );
              call.answer(combinedStream);

              if (userStoppedScreenSharing && combinedStream) {
                setScreenShareLoading(false);
              }

              // Optional: Clear interval when the call ends
              call.on('close', () => {
              });
            }
          });


          socket.on("isuserconnected", ({ assessment_id_secret }) => {

            console.log("asked for user connected");
            //@ts-ignore
            socket.emit("send-user-connected", {
              peerID,
              ttu_id: userid,
              assessment_id_secret,
            });
          });

          if (userStoppedScreenSharing) {
            socket.emit("update-client-side-count-for-room", {
              ttu_id: userid,
              assessment_id_secret: id,
            });
          }

          socket.on("reconnecting-user", ({ employeeEmail, assessment_id_secret }) => {
            if (employeeEmail?.toLowerCase() === localstoragedata.email?.toLowerCase()) {
              socket.emit("send-user-reconnected", {
                peerID,
                ttu_id: userid,
                assessment_id_secret,
              });
            }
          });


          socket.on("kick-user", ({ employeeEmail }) => {
            if (employeeEmail?.toLowerCase() === localstoragedata.email?.toLowerCase()) {

              const notification = new Notification("Your test session has been suspended, due to activity requiring further investigation", options);

              notification.onclick = function () {
                if (document.hidden) {
                  window.focus();
                }
              };

              setIsModalOpenKick(true);
              modalsRef.current.kick_user_modal = 1;
              // setIsSubmitting(true);
              setTimeout(async() => {
                socket.emit("user-disconnect", {
                  assessment_id_secret: id,
                  msg: "user has been kicked out from assessment",
                  userremoved: 1,
                  is_reload: false,
                  userid: userId,
                  organization_id: fetchedoverviewdata?.organization_id ?? 0,
                });
                // navigate(`/dashboard/attemptedtest/step3/${id}`);
                await submithandler(1);
              }, 22000);
            }
          })

          socket.on("msg-user", ({ employeeEmail, msg }) => {
            if ((employeeEmail?.toLowerCase() === localstoragedata.email?.toLowerCase()) && msg) {

              const notification = new Notification(msg, options);

              notification.onclick = function () {
                if (document.hidden) {
                  window.focus();
                }
              };

              const datapayload = {
                messages: msg,
                severity: "info",
                autoClose: false,
              };
              dispatch(handleopensnackbar(datapayload));

            }
          });

          socket.on("broadcast-message", ({ message }) => {
            const notification = new Notification(message, options);

            notification.onclick = function () {
              if (document.hidden) {
                window.focus();
              }
            };

            const datapayload = {
              messages: message,
              severity: "info",
              autoClose: false,
            };
            dispatch(handleopensnackbar(datapayload));
          });

        });
    }
  }, [recorderInstance, id, webcamRef]);

  //handle reload when user reloads the page or closes the tab
  useEffect(() => {
    let isReloading = false; // Flag to track if the user confirms the reload

    const handleBeforeUnload = (event: any) => {
      // Trigger the confirmation dialog
      if (!tokenid) {
        event.preventDefault();
        event.returnValue = ""; // Required to display the confirmation dialog
      }

      // Set flag for reload
      isReloading = true;
    };

    const handleUnload = async () => {
      try {
        if (!tokenid) {
          sessionStorage.setItem("isReloading", "true");

          if (socketinstance) {
            console.log(`Removing user ${userId} from the test room`);
            sessionStorage.removeItem("userPeerID");

            socketinstance.emit("user-disconnect", {
              assessment_id_secret: id,
              userremoved: 1,
              is_reload: true,
              userid: userId || 0,
              msg: "User disconnected due to closing or reload",
              organization_id: fetchedoverviewdata?.organization_id ?? 0,
            });
          }

          // Execute submithandler
          await submithandler();

          const data = `Client Side --> Browser tab closed or reloaded for user with email ${localstoragedata.email}`;
          sendLog(data, false);
        }
      } catch (error) {
        console.error("Error during unload operations:", error);
      }
    };

    const handlePageReload = async () => {
      const isReloading = sessionStorage.getItem("isReloading") === "true";

      if (isReloading && !tokenid) {
        console.log("Re-executing logic after reload...");

        // Clear reload flag
        sessionStorage.removeItem("isReloading");

        // Re-execute logic after reload
        try {
          await submithandler();

          if (socketinstance) {
            console.log(`Removing user ${userId} from the test room`);
            sessionStorage.removeItem("userPeerID");

            socketinstance.emit("user-disconnect", {
              assessment_id_secret: id,
              userremoved: 1,
              is_reload: true,
              userid: userId || 0,
              msg: "User disconnected due to closing or reload",
              organization_id: fetchedoverviewdata?.organization_id ?? 0,
            });

          }

          const data = `Client Side --> Browser tab closed or reloaded for user with email ${localstoragedata.email}`;
          sendLog(data, false);
        } catch (error) {
          console.error("Error during reload logic:", error);
        }
      }
    };

    // Run the logic after reload
    (async () => {
      await handlePageReload();
    })();

    // Add event listeners for `beforeunload` and `unload`
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    // Clean up event listeners
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [socketinstance, id, userId, submithandler, fetchedoverviewdata, uploadQueueRef]);

  //useEffect for connecting peer to server when user starts screen recording and camera for live proctoring
  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.live_proctoring_screen == "true") &&
      !peerCallInstance &&
      !socketinstance &&
      !userId &&
      !userStoppedScreenSharing
    ) {
      console.log('🙋‍♂️ inside this');
      if ((fetchedsetting?.live_proctoring_camera == "true")) {
        if (webcamRef) {
          connectPeer();
        }
      } else {
        connectPeer();
      }
    }

    return () => {
      try {
        if (
          recorderInstance &&
          fetchedsetting?.live_proctoring_screen == "true" &&
          peerCallInstance
        ) {
          // peerCallInstance?.close();
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [
    connectPeer,
    peerCallInstance,
    recorderInstance,
    socketinstance,
    fetchedsetting?.live_proctoring_screen,
    userId,
    webcamRef
  ]);

  // console.log("userStoppedScreenSharing", userStoppedScreenSharing);
  // console.log("userId", userId);
  // console.log("socketinstance", socketinstance);
  // console.log("peerCallInstance", peerCallInstance);

  //useEffect for connecting peer to server when user stops screen recording and camera for live proctoring
  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.live_proctoring_screen == "true") &&
      peerCallInstance &&
      !socketinstance &&
      userId &&
      userStoppedScreenSharing
    ) {
      console.log('🙋‍♂️ inside this 2');
      if ((fetchedsetting?.live_proctoring_camera == "true")) {
        if (webcamRef) {
          connectPeer();
        }
      } else {
        connectPeer();
      }
    }
  }, [
    connectPeer,
    peerCallInstance,
    recorderInstance,
    socketinstance,
    fetchedsetting?.live_proctoring_screen,
    userId,
    webcamRef
  ]);

  //useEffect for submiting test after timer completes
  useEffect(() => {
    if (timerComplete && !manualSubmit && !tokenid) {
      submithandler(1);
    } else if (timerComplete && tokenid) {
      window.location.href = `/preview-testpage/${tokenid}`;
    }
  }, [timerComplete, manualSubmit])
  // useEffect(() => {
  //   if (timerComplete && !manualSubmit) {
  //     submithandler(1);
  //   }

  // }, [timerComplete, manualSubmit])


  // useEffect for sending anticheating events for image capture, live proctoring camera and live proctoring screen 
  //and screen recording when modals are shown in test for faces or screen recording stopped to show after test started 
  //after how much interval user stoped camera or screen recording.
  useEffect(() => {
    if (fetchedsetting?.randomphotos == "true" ||
      fetchedsetting?.live_proctoring_camera == "true" && !tokenid) {
      if (isModalOpenCamera && !isSubmitting && facesDetected === 0) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          end: true,
          time: returnUTCDate(),
          session_id: sessionId.current === 0 ? null : sessionId.current,
          columnName: AntiCheatingColumnName.cameraStopped,
        };
        (async () => {
          try {
            storeAntiCheatingEvent(dataForCamera);
            // await antiCheatingEvents(dataForCamera);
          } catch (error) {
            console.log("Error occured while sending anticheating events for camera:", error);
          }
        })();
      } else if (!isModalOpenCamera && !isSubmitting && facesDetected > 0) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          start: true,
          time: returnUTCDate(),
          session_id: sessionId.current === 0 ? null : sessionId.current,
          columnName: AntiCheatingColumnName.cameraStopped,
        };

        (async () => {
          try {
            storeAntiCheatingEvent(dataForCamera);
            // await antiCheatingEvents(dataForCamera);
          } catch (error) {
            console.log("Error occured while sending anticheating events for camera:", error);
          }
        })();
      }
    }
  }, [fetchedsetting, tokenid, isModalOpenCamera, isSubmitting, facesDetected])


  //Memoized camera warning component
  const memoizedCameraWarn = React.useMemo(() => (
    <CameraWarn
      isModalOpenResize={isModalOpenCamera}
      setIsModalOpenResize={setIsModalOpenCamera}
      facesDetected={facesDetected}
    />
  ), [isModalOpenCamera, facesDetected, setIsModalOpenCamera]);

  // console.log("facesDetected", facesDetected);

  //useEffect to calculate user activity, keystrokes, mouse clicks, and scrolls.
  useEffect(() => {
    const handleKeystroke = () => {
      activityRef.current.keystrokes++;
    };

    const handleClick = () => {
      activityRef.current.clicks++;
    };

    const handleScroll = () => {
      activityRef.current.scrolls++;
    };

    document.addEventListener("keydown", handleKeystroke);
    document.addEventListener("click", handleClick);
    document.addEventListener("scroll", handleScroll);

    const INTERVAL = process.env.REACT_APP_USER_ACTIVITY_TRACKER_INTERVAL ?? 60000;

    const interval = setInterval(async () => {
      if (fetchedsetting.anti_cheating == 'true' && !tokenid) {
        const { clicks, keystrokes, scrolls, perMinuteData, totalMinutes } = activityRef.current;
        perMinuteData.push({ clicks, keystrokes, scrolls });

        let data: any = {
          minuteIndex: totalMinutes,
          payload: [clicks, keystrokes, scrolls]
        };

        let payload = {
          tableName: 'test_taker_sessions',
          columnName: 'logs',
          type: LogType.TYPING_AND_MOUSE,
          data,
        }

        // console.log("🚀🚀 payload", payload);

        await retryRequest(`getexamdata/store-data-for-logs/${sessionId.current}`, payload);

        // Reset counts for the next minute
        activityRef.current.clicks = 0;
        activityRef.current.keystrokes = 0;
        activityRef.current.scrolls = 0;

        activityRef.current.totalMinutes++;
      }
    }, Number(INTERVAL));

    return () => {
      document.removeEventListener("keydown", handleKeystroke);
      document.removeEventListener("click", handleClick);
      document.removeEventListener("scroll", handleScroll);
      clearInterval(interval);
    };
  }, [fetchedsetting]);


  return (
    <div onCopy={blockCopyAndPasteHandler} onPaste={blockCopyAndPasteHandler}>
      {/* begin::Row */}
      {tokenid ? (
        <>
          <div ref={myRef} className="row g-5 g-xl-10 mb-5 mb-xl-10">
            {/* begin::Col */}

            <div style={{ width: "100%" }}>
              {/* add tests question here */}
              {sortnofetched && sortnofetched.length > 0
                ? fetcheddatawhole?.map((each: any) => {
                  return (
                    <Questioncardwidget
                      isVideoUploading={false}
                      changehandlervideo={() => {
                        console.log("changehandlervideo");
                      }}
                      changehandler={changehandler}
                      changehandlerimage={changehandlerimage}
                      changehandlercheckbox={changehandlercheckbox}
                      particularvalue={particularvalue}
                      setparticularvalue={setparticularvalue}
                      autorecoverydata={autorecoverydata}
                      className="h-md-100"
                      datawhole={each}
                      fileName={fileName[each.sortno] ?? ''}
                      setFileName={setFileName}
                      setFileType={setFileType}
                      isUploading={isUploading}
                      fileType={fileType[each.sortno] ?? ''}
                      setAlertType={setAlertType}
                      setAlertMessage={setAlertMessage}
                      setAlertSnackBarOpen={setAlertSnackBarOpen}
                    />
                  );
                })
                : [...Array(5)].map((_, i) => (
                  <Box
                    key={`skeleton-${i}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      sx={{ width: "100%", height: 250, marginBottom: 5 }}
                    />
                  </Box>
                ))}
            </div>
            {/* end::Col */}


            <div className="submitdiv">
              <Button
                className="btn2"
                sx={{
                  padding: "12px !important",
                  fontSize: "12px !important",
                }}
                id="submittestbutton"
                onClick={() => {
                  window.location.href = `/preview-testpage/${tokenid}`;
                }}
              >
                {intl.formatMessage({ id: "TEST.SUBMIT" })}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <OGPAdd testid={id} fetchedoverviewdata={fetchedoverviewdata} />

          <div ref={myRef} className="row g-5 g-xl-10 mb-5 mb-xl-10">
            {/* begin::Col */}

            <div style={{ width: "100%" }}>
              {/* add tests question here */}
              {sortnofetched && sortnofetched.length > 0
                ? fetcheddatawhole?.map((each: any, index: any) => {
                  return (
                    <div key={index}>
                      {
                        <Questioncardwidget
                          changehandlervideo={changehandlervideo}
                          isVideoUploading={isVideoUploading}
                          changehandler={changehandler}
                          changehandlerimage={changehandlerimage}
                          changehandlercheckbox={changehandlercheckbox}
                          particularvalue={particularvalue}
                          autorecoverydata={autorecoverydata}
                          setparticularvalue={setparticularvalue}
                          className="h-md-100"
                          datawhole={each}
                          fileName={fileName[each.sortno] ?? ''}
                          setFileName={setFileName}
                          setFileType={setFileType}
                          isUploading={isUploading}
                          fileType={fileType[each.sortno] ?? ''}
                          setAlertType={setAlertType}
                          setAlertMessage={setAlertMessage}
                          setAlertSnackBarOpen={setAlertSnackBarOpen}
                        />
                      }
                    </div>
                  );
                })
                : [...Array(5)].map((_, i) => (
                  <Box
                    key={`skeleton-${i * 111}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      sx={{ width: "100%", height: 250, marginBottom: 5 }}
                    />
                  </Box>
                ))}
            </div>
            {/* end::Col */}

            {/* 
            isvideouploading -- if is video uploading then show loader and keep submit button disabled
            saveRecordingButton  -- you can use this classname directly without any validation only avaialble for which is not save and recorded
            classname
            */}

            {sortnofetched && sortnofetched.length > 0 && (
              <div className="submitdiv">
                {isVideoUploading && <CircularProgress />}
                <Button
                  disabled={isVideoUploading}
                  className="btn2"
                  sx={{
                    padding: "12px !important",
                    fontSize: "12px !important",
                  }}
                  id="submittestbutton"
                  onClick={saveAllRecording}
                >
                  {intl.formatMessage({ id: "TEST.SUBMIT" })}
                </Button>

                {/* </Online> */}
              </div>
            )}
          </div>
          {/* <Online> */}
          <SubmitModal
            handleClose={handleClose}
            show={show}
            submithandler={async () => {
              setManualSubmit(true);
              submithandler(1);
            }}
          />

          {/* end::Row */}
        </>
      )}
      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10"></div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {(fetchedsetting?.randomphotos == "true" ||
        fetchedsetting?.live_proctoring_camera == "true") &&
        sortnofetched &&
        sortnofetched.length > 0 && (
          <Camerafortest
            tokenid={tokenid}
            setwebcamRef={setwebcamRef}
            sendLog={sendLog}
            openmodalForCamera={openmodalForCamera}
            closemodalForCamera={closemodalForCamera}
            setFacesDetected={setFacesDetected}
            isSubmitting={isSubmitting}
            sessionId={sessionId.current}
          />
        )}

      {fetchedsetting?.anti_cheating == "true" &&
        fetchedsetting?.fullscreen == "true" ? (
        <FullscreenModal
          setIsModalOpenResize={setIsModalOpenresize}
          isModalOpenResize={isModalOpenresize}
        />
      ) : null}

      {fetchedsetting?.anti_cheating == "true" &&
        fetchedsetting?.no_switch_tabs_allowed == "true" ? (
        <WindowSwitchModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalsRef={modalsRef}
        />
      ) : null}
      {
        <KickedUserModal
          isModalOpenKick={isModalOpenKick}
          setIsModalOpenKick={setIsModalOpenKick}
          org_name={fetchedoverviewdata?.conductedby || "your organization"}
        />
      }
      {(fetchedsetting?.randomphotos == "true" ||
        fetchedsetting?.live_proctoring_camera == "true") &&
        memoizedCameraWarn}
      {(fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") && (
          <ShareEntireScreenModal
            isModalOpen={displayEntireScreenModal}
            setIsModalOpen={setDisplayEntireScreenModal}
            modalsRef={modalsRef}
            handleShareScreen={handleStartRecording}
          />
        )}

      {(fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") && (
          <ScreenWarn
            isModalOpenResize={isModalOpenScreen}
            setIsModalOpenResize={setIsModalOpenScreen}
            allowScreenShareFunc={handleStartRecording}
            screenShareLoading={screenShareLoading}
          />
        )}

      {(fetchedsetting?.blockinspect == "true" ||
        fetchedsetting?.blockrightclick == "true") && (
          <InspectDevToolsModal isModalOpenResize={openmodalForInspectDevTools} />
        )}

      {!isOnline && (
        <InternetTurnOffModal isModalOpenResize={!isOnline} />
      )}

      {fetchedsetting?.allowscreenrecording == "true" && (
        <DataUploadingModal
          isModalOpenResize={openDataUploadModal}
          uploadProgress={uploadProgress}
        />
      )}

      {
        alertSnackBarOpen && alertType && <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={alertSnackBarOpen}
          autoHideDuration={5000}
          onClose={() => setAlertSnackBarOpen(false)}
          TransitionComponent={(props) => <Slide {...props} direction="right" />}
        >
          <Alert
            onClose={() => setAlertSnackBarOpen(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%", fontSize: 14 }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      }


    </div>
  );
};


const Testwrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <Testpage />
    </>
  );
};

export { Testwrapper };

