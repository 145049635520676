import { useEffect, useState } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardModal from "../../../../../app/pages/dashboard/dashboardModal/DashboardModal";
import { VscInspect } from "react-icons/vsc";
import { BsCamera, BsMouse } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { FiCopy, FiDownload } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { GrDocumentPdf, GrDownload } from "react-icons/gr";
import { MdOutlineSchedule } from "react-icons/md";
import "./CardsWidget21.css";
import Modalfortestreview from "../../../../../app/pages/submittedtest/Modalfortestreview/Modalfortestreview";
import Modalforremarks from "../../../../../app/pages/submittedtest/Modalforremarks/Modalforremarks";
import { AiOutlineReload } from "react-icons/ai";
import Modalforanticheatreport from "../../../../../app/pages/submittedtest/ModalforAnticheatreport/Modalforanticheatreport";
import { Box, Typography } from "@mui/material";
import { toAbsoluteUrl } from "../../../../helpers";
import { StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

type Props = {
  className: string;
  description: string;
  title: string;
  color: string;
  img: string;
  duration: string;
  createdat: string;
  testid: string;
  scheduled: string;
  test_settings: any;
  totalattempted: number;
  resultstatus: number;
  outof: number;
  istestseries: number;
  isdownload: number;
  score: number;
  remarks: number;
  testtakersessionid: number;
  remarks_description?: string;
  total_marks: number;
  question_sort_no: any;
  view_remarks_description: any;
  alloted_questions?: any;
  company_name: string;
  company_logo: string;
  // test_started_at: any;
};

const renderTooltip2 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Download certificate pdf
  </Tooltip>
);

export function CardsWidget21({
  className,
  description,
  title,
  color,
  img,
  duration,
  createdat,
  testid,
  scheduled,
  test_settings,
  totalattempted,
  remarks,
  resultstatus,
  outof,
  istestseries,
  isdownload,
  score,
  testtakersessionid,
  remarks_description,
  total_marks,
  question_sort_no,
  view_remarks_description,
  alloted_questions,
  company_name,
  company_logo,
}: // test_started_at
Props) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);

  // let year, date, month, hour, minute, seconds

  // [year, date, month] = (test_started_at).slice(0, 10).split("-");
  // [hour, minute, seconds] = (test_started_at).slice(11).split(":")

  return (
    <>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className} attemptedseriesmaindiv`}
        style={{
          backgroundColor: "var(--kt-app-sidebar-bg-color)",
          border: "1px solid var(--kt-app-layout-text-color)",
          backgroundImage: `url('${img}')`,
          height: "fit-content",
        }}
      >
        <div
          className="card-header pt-1"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            className="card-title d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            {resultstatus && resultstatus === 1 && score != null ? (
              <h2
                className="fw-bolder text-black me-1"
                style={{
                  cursor: "pointer",
                  color: "var(--kt-app-layout-text-color) !important",
                }}
                onClick={handleShow}
              >
                {title}
              </h2>
            ) : (
              <h2
                className="fw-bolder text-black me-1"
                style={{ color: "var(--kt-app-layout-text-color) !important" }}
              >
                {title}
              </h2>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <StyledTypography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  letterSpacing: "1px",
                  fontSize: { xs: 8, sm: 12, xl: 14 },
                  color: "var(--kt-app-layout-grey-text-color) !important",
                }}
              >
                Conducted by:
              </StyledTypography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box
                  component="img"
                  src={
                    company_logo
                      ? company_logo
                      : toAbsoluteUrl("/media/avatars/placeholder_img.png")
                  }
                  alt="Company Logo"
                  sx={{
                    width: 28,
                    height: 28,
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <StyledTypography
                  variant="body2"
                  sx={{
                    fontWeight: 600,
                    letterSpacing: "1px",
                    fontSize: { xs: 8, sm: 12, xl: 14 },
                    color: "var(--kt-app-layout-text-color) !important",
                  }}
                >
                  {company_name ?? ""}
                </StyledTypography>
              </Box>
            </Box>
          </div>
        </div>
        <div className="card-body  align-items-end pt-0">
          {/* {test_started_at && (
            <div>
              <div
              >
                {" "}
                {hour + ":" + minute + " " + date + "-" + month + "-" + year}
              </div>
            </div>
          )} */}

          <div className="d-flex align-items-start flex-column mt-0w-100">
            { view_remarks_description === 1  &&
              <StyledTypography component='p' sx={{
                fontSize: { xs: 12, sm: 16, xl: 18 },
                fontWeight: 600,
                color: "var(--kt-app-layout-grey-text-color) !important"
              }}>
                Remark: {view_remarks_description === 1 && remarks === 1 && (
                <StyledTypography
                  component="span"
                  sx={{
                    color: "green",
                    marginY: 1,
                    fontSize: { xs: 12, sm: 16, xl: 18 },
                    fontWeight: 600,
                  }}
                >
                  Pass
                </StyledTypography>
              )}
              {view_remarks_description === 1 && remarks === 0 && (
                <StyledTypography
                  component="span"
                  sx={{
                    color: "red",
                    marginY: 1,
                    fontSize: { xs: 12, sm: 16, xl: 18 },
                    fontWeight: 600,
                  }}
                >
                  Fail
                </StyledTypography>
              )}
              </StyledTypography>
            }
            
            {remarks_description && view_remarks_description ? (
                <p
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    margin: "10px 0px",
                    color: "var(--kt-app-layout-text-color) !important",
                  }}
                  onClick={handleShow2}
                >
                  {" "}
                  View Remarks Description
                </p>
              ) : null}


            <div className="d-flex justify-content-between fw-bold fs-6 text-black opacity-75 w-100  mb-4">
              <span
                className="attemptedquestion fw-bold"
                style={{
                  color: "var(--kt-app-layout-grey-text-color) !important",
                }}
              >
                Total attempted questions : {totalattempted && totalattempted} /{" "}
                {outof && outof}
              </span>
            </div>

            <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
              style={{ marginTop: "-18px !important" }}
            >
              {resultstatus && resultstatus === 1 && score != null ? (
                <span>
                  <span
                    style={{
                      marginLeft: "0px",
                      color: "var(--kt-app-layout-grey-text-color) !important",
                    }}
                    className="result-span"
                  >
                    {score >= 0 && `have scored :`}
                  </span>{" "}
                  <span style={{ color: "lime" }}>
                    {`${score} out of ${total_marks}`}
                  </span>
                  <p
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginTop: "20px",
                      marginBottom: "-8px",
                      color: "var(--kt-app-layout-text-color) !important",
                    }}
                    onClick={handleShow}
                  >
                    {" "}
                    View Answers
                  </p>
                  {/* <p
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginTop: "20px",
                      marginBottom: "-8px",
                      color: "#FBFFFF",
                    }}
                    onClick={handleShow3}
                  >
                    {" "}
                    View Anithcheat report
                  </p> */}
                </span>
              ) : (
                <span>
                  <span style={{ color: "#bb0000" }}>
                    Submitted for evaluation
                  </span>{" "}
                </span>
              )}

              {/* {JSON.parse(test_settings).randomphotos && JSON.parse(test_settings).randomphotos == 'true' && <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip4}
              >
                <p>

                  <BsCamera style={{ cursor: 'pointer' }} size={20} />
                </p>
              </OverlayTrigger>}
              {scheduled && <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <p>

                  <MdOutlineSchedule style={{ cursor: 'pointer' }} size={20} />
                </p>
              </OverlayTrigger>} */}

              {istestseries == 1 && (
                <div>
                  <Link
                    to=""
                    id="retestid"
                    onClick={() => {
                      window.location.href = `/testoverview/${testid}`;
                    }}
                    style={{ color: "#03045e", fontWeight: "bold" }}
                  >
                    {" "}
                    Re-Attempt <AiOutlineReload size={20} color="#03045e" />
                  </Link>
                </div>
              )}
            </div>
            {show && (
              <Modalfortestreview
                show={show}
                handleClose={handleClose}
                testtakersessionid={testtakersessionid}
                title={title}
                testid={testid}
                remarks={remarks}
                score={score}
                question_sort_no={question_sort_no}
                view_remarks_description={view_remarks_description}
              />
            )}
            {show2 && remarks_description && (
              <Modalforremarks
                show={show2}
                handleClose={handleClose2}
                title={title}
                remarks={remarks}
                remarks_description={remarks_description}
                score={score}
              />
            )}
            {show3 && (
              <Modalforanticheatreport
                show={show3}
                handleClose={handleClose3}
                testtakersessionid={testtakersessionid}
                title={title}
                testid={testid}
                remarks={remarks}
                score={score}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
